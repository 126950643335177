/**
 * @generated SignedSource<<0d8ea410899b5a767647bdc65e0790a9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type plusWidgetQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"plusWidgetHasAccessQuery">;
  readonly " $fragmentType": "plusWidgetQuery";
};
export type plusWidgetQuery$key = {
  readonly " $data"?: plusWidgetQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"plusWidgetQuery">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "applicationId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "dailyPulseTypeUuId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "highlightQueueName"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "plusNewsAgendaEndDate"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "plusNewsAgendaStartDate"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "plusNewsAgendaTypes"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "plusWidgetQuery",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "applicationId",
          "variableName": "applicationId"
        },
        {
          "kind": "Variable",
          "name": "dailyPulseTypeUuId",
          "variableName": "dailyPulseTypeUuId"
        },
        {
          "kind": "Variable",
          "name": "highlightQueueName",
          "variableName": "highlightQueueName"
        },
        {
          "kind": "Variable",
          "name": "plusNewsAgendaEndDate",
          "variableName": "plusNewsAgendaEndDate"
        },
        {
          "kind": "Variable",
          "name": "plusNewsAgendaStartDate",
          "variableName": "plusNewsAgendaStartDate"
        },
        {
          "kind": "Variable",
          "name": "plusNewsAgendaTypes",
          "variableName": "plusNewsAgendaTypes"
        }
      ],
      "kind": "FragmentSpread",
      "name": "plusWidgetHasAccessQuery"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "74c56d307c2755fb5043789a8e8e55de";

export default node;
