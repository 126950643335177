import { useTrackImpressionEventByIntersection } from "@product/scmp-sdk";
import first from "lodash/first";
import { type FunctionComponent, useCallback } from "react";
import { graphql, useFragment } from "react-relay";

import { BaseLinkContextProvider } from "scmp-app/components/common/base-link/context";
import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import type { RecirculationWidgetImpressionEvent } from "scmp-app/components/tracking/google-analytics-4/types";
import { tracking } from "scmp-app/data";
import { getAbsoluteUrl } from "scmp-app/lib/utils";
import type { heroArticleArticle$key } from "scmp-app/queries/__generated__/heroArticleArticle.graphql";

import { StyledContentItemHomeHeroArticle } from "./styles";

type Props = {
  reference: heroArticleArticle$key;
};

export const HomeHeroArticle: FunctionComponent<Props> = ({ reference }) => {
  const article = useFragment(
    graphql`
      fragment heroArticleArticle on Article {
        ...homeHeroArticleContentItemContent
        entityId
        moreOnThisArticles {
          entityId
        }
      }
    `,
    reference,
  );

  const listOfArticles = [article.entityId, first(article.moreOnThisArticles)?.entityId ?? ""].join(
    ",",
  );

  const impressionGA4Event = useCallback<() => RecirculationWidgetImpressionEvent>(
    () => ({
      action: "imp",
      category: "recirculation",
      customized_parameters: {
        list_of_articles: listOfArticles,
        page_type: "homepage",
        widget_name: "top_story1",
      },
      subcategory: "widget",
    }),
    [listOfArticles],
  );

  const { captureTrackImpressionEventTargetElement } =
    useTrackImpressionEventByIntersection<RecirculationWidgetImpressionEvent>({
      ga4TrackingHandler: sendGA4Tracking,
      getGa4Event: impressionGA4Event,
      options: { isSendGA4Tracking: true, shouldSendOnce: true },
    });
  if (!article) return null;

  return (
    <BaseLinkContextProvider
      customQueryParameters={{
        module: tracking.module.TopStory,
      }}
    >
      <StyledContentItemHomeHeroArticle
        onClick={(entityId, urlAlias, isMoreOnThis) => {
          sendGA4Tracking({
            action: "click",
            category: "recirculation",
            customized_parameters: {
              article_id: entityId,
              article_seq: isMoreOnThis ? "1b" : "1",
              destination_url: getAbsoluteUrl(urlAlias),
              page_type: "homepage",
              widget_name: "top_story1",
            },
            subcategory: "widget",
          });
        }}
        ref={captureTrackImpressionEventTargetElement}
        reference={article}
        topicLinkVariant={{ type: "main" }}
      />
    </BaseLinkContextProvider>
  );
};

HomeHeroArticle.displayName = "HomeHeroArticle";
