import { theme, useResponsive } from "@product/scmp-sdk";
import { type FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";
import type { FragmentRefs } from "relay-runtime";

import type { superSplashLargeLiveWidget$key } from "scmp-app/queries/__generated__/superSplashLargeLiveWidget.graphql";

import type { LabelType } from "./styles";
import {
  Container,
  Contents,
  Description,
  Label,
  LabelContainer,
  RestArticlesContainer,
  StyledContentItemHomeSuperSplashLargePrimary,
  StyledContentItemHomeSuperSplashLiveSecondary,
  TwoArticlesInDesktopContainer,
  TwoArticlesInMobileContainer,
} from "./styles";

type Props = {
  className?: string;
  labelType: LabelType;
  reference: superSplashLargeLiveWidget$key;
};

export const HomeSuperSplashLarge: FunctionComponent<Props> = ({
  className,
  labelType,
  reference: reference_,
}) => {
  const data = useFragment(
    graphql`
      fragment superSplashLargeLiveWidget on LiveWidget {
        label
        description
        relatedArticles {
          ...homeSuperSplashLargePrimaryContentItemContent
          ...homeSuperSplashLiveSecondaryContentItemContent
        }
      }
    `,
    reference_,
  );

  const label = data?.label;
  const description = data?.description;
  const [firstArticle, secondArticle, thirdArticle, ...restArticles] = data?.relatedArticles ?? [];
  const superSplashTwoArticles = [secondArticle, thirdArticle];

  const isMobile = useResponsive(theme.breakpoints.only("mobile"), false);

  const renderContent = (
    articles: Nullish<{
      readonly " $fragmentSpreads": FragmentRefs<"homeSuperSplashLiveSecondaryContentItemContent">;
    }>[],
  ) =>
    articles.map(
      (article, key) =>
        article && (
          <StyledContentItemHomeSuperSplashLiveSecondary
            key={key}
            reference={article}
            withComment={isMobile}
          />
        ),
    );

  return (
    <Container className={className}>
      <LabelContainer $labelType={labelType}>
        <Label $labelType={labelType}>{label}</Label>
        {description && <Description $labelType={labelType}>{description}</Description>}
      </LabelContainer>
      <Contents>
        {firstArticle && (
          <StyledContentItemHomeSuperSplashLargePrimary reference={firstArticle}>
            <TwoArticlesInDesktopContainer>
              {renderContent(superSplashTwoArticles)}
            </TwoArticlesInDesktopContainer>
          </StyledContentItemHomeSuperSplashLargePrimary>
        )}
        <TwoArticlesInMobileContainer>
          {renderContent(superSplashTwoArticles)}
        </TwoArticlesInMobileContainer>
        {restArticles?.length > 0 && (
          <RestArticlesContainer>{renderContent(restArticles)}</RestArticlesContainer>
        )}
      </Contents>
    </Container>
  );
};

HomeSuperSplashLarge.displayName = "HomeSuperSplashLarge";
