import styled from "@emotion/styled";
import { fontMerriweather, fontRoboto } from "@product/scmp-sdk";

import { BaseLink } from "scmp-app/components/common/base-link";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  padding: 20px;

  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
`;

export const LogoContainer = styled.div`
  border-block-end: 1px solid #000000;

  padding-block-end: 8px;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  align-items: flex-start;

  padding-block: 16px 20px;

  border-block-end: 1px solid rgba(0, 0, 0, 0.1);

  ${props => props.theme.breakpoints.up("tablet")} {
    flex-direction: row;
    align-items: center;

    column-gap: 32px;
    border-block-end: 0;
  }

  ${props => props.theme.breakpoints.up("desktop")} {
    flex-direction: column;
    align-items: flex-start;

    border-block-end: 1px solid rgba(0, 0, 0, 0.1);
  }
`;

export const Title = styled.div`
  font-weight: 300;
  font-size: 24px;
  font-family: ${fontMerriweather};
  line-height: 120%;
`;

export const SubTitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  font-family: ${fontRoboto};
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  padding-block-end: 0;
  border-block-end: 0;

  ${props => props.theme.breakpoints.up("tablet")} {
    padding-block-end: 20px;
    border-block-end: 1px solid rgba(0, 0, 0, 0.1);
  }

  ${props => props.theme.breakpoints.up("desktop")} {
    padding-block-end: 0;
    border-block-end: 0;
  }
`;

export const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  align-items: flex-start;

  margin-block-start: 20px;

  ${props => props.theme.breakpoints.up("tablet")} {
    display: flex;
    flex-direction: row;
    align-items: center;

    column-gap: 20px;
  }

  ${props => props.theme.breakpoints.up("desktop")} {
    flex-direction: column;
    row-gap: 20px;
    align-items: flex-start;
  }
`;

export const DownloadContainer = styled.div`
  color: #666666;
  font-weight: 400;
  font-size: 14px;
  font-family: ${fontRoboto};
  line-height: normal;
`;

export const StyledBaseLink = styled(BaseLink)`
  inline-size: fit-content;

  color: #4585ff;

  &:hover {
    text-decoration-line: underline;
  }
`;

export const LogoBaseLink = styled(BaseLink)`
  line-height: 0;
`;
