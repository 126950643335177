import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { fontRobotoCondensed, theme } from "@product/scmp-sdk";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";

import { ContentItemHomeAdvertiser } from "scmp-app/components/content/content-item-render/variants/home-advertiser";

import ArrowNext from "./arrow-next.svg";

export const StyledContentItemHomeAdvertiser = styled(ContentItemHomeAdvertiser)``;

export const Container = styled.div`
  padding-block-start: 16px;
  border-block-start: 1px solid #000000;
`;

export const Title = styled.div`
  margin-block-end: 24px;

  color: rgb(0, 0, 0, 0.5);
  font-size: 20px;
  font-family: ${fontRobotoCondensed};
  line-height: 23px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SwiperContainer = styled.div`
  grid-column: 1 / span 12;

  inline-size: 100%;
  ${theme.breakpoints.up("desktop")} {
    grid-column: 2 / span 11;
  }
`;

export const SwiperWrapper = styled.div`
  position: relative;

  overflow: visible;

  &:hover {
    .card-swiper-button-prev svg,
    .card-swiper-button-next svg {
      opacity: 1;
    }
  }

  .card-swiper-button-prev svg,
  .card-swiper-button-next svg {
    opacity: 0;

    transition: opacity 1s;
  }

  .card-swiper-button-disabled {
    opacity: 0;
  }
`;

export const StyledSwiper = styled(Swiper)`
  block-size: auto;

  overflow-y: hidden;

  .swiper-slide {
    block-size: auto;
  }
`;

export const StyledSwiperSlide = styled(SwiperSlide)`
  max-inline-size: 235px;
  ${theme.breakpoints.up("desktop")} {
    max-inline-size: 216px;
  }
`;

const ArrowStyle = css`
  inset-block-start: 50%;

  display: none;

  ${theme.breakpoints.up("tablet")} {
    position: absolute;
    z-index: 1;

    display: block;

    cursor: pointer;
  }
`;

export const StyledArrowNext = styled(ArrowNext)`
  ${ArrowStyle}

  transform: translateY(-50%);
  ${theme.breakpoints.up("tablet")} {
    inset-inline-end: -8px;
  }
  ${theme.breakpoints.up("desktop")} {
    flex-direction: row;
    align-items: flex-start;
  }
`;

export const StyledArrowPrevious = styled(ArrowNext)`
  ${ArrowStyle}

  transform: translateY(-50%) rotate(180deg);
  ${theme.breakpoints.up("tablet")} {
    inset-inline-start: -8px;
  }
  ${theme.breakpoints.up("desktop")} {
    flex-direction: row-reverse;
    align-items: flex-start;
  }
`;
