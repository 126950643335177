/**
 * @generated SignedSource<<faf7e35d96484d2fef7623305520f16b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type sectionTopQuery$data = {
  readonly chinaSection: {
    readonly name: string;
    readonly subSections: {
      readonly items: {
        readonly edges: ReadonlyArray<{
          readonly " $fragmentSpreads": FragmentRefs<"subsectionMenuQueue">;
        }>;
      } | null | undefined;
    } | null | undefined;
    readonly topStories: {
      readonly items: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly entityId?: string;
          };
          readonly " $fragmentSpreads": FragmentRefs<"oneSectionTopWidgetQueue" | "twoSectionTopWidgetQueue">;
        }>;
      } | null | undefined;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"subsectionMenuSection">;
  };
  readonly hongKongSection?: {
    readonly name: string;
    readonly subSections: {
      readonly items: {
        readonly edges: ReadonlyArray<{
          readonly " $fragmentSpreads": FragmentRefs<"subsectionMenuQueue">;
        }>;
      } | null | undefined;
    } | null | undefined;
    readonly topStories: {
      readonly items: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly entityId?: string;
          };
          readonly " $fragmentSpreads": FragmentRefs<"oneSectionTopWidgetQueue" | "twoSectionTopWidgetQueue">;
        }>;
      } | null | undefined;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"subsectionMenuSection">;
  };
  readonly lifestyleSection: {
    readonly name: string;
    readonly subSections: {
      readonly items: {
        readonly edges: ReadonlyArray<{
          readonly " $fragmentSpreads": FragmentRefs<"subsectionMenuQueue">;
        }>;
      } | null | undefined;
    } | null | undefined;
    readonly topStories: {
      readonly items: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly entityId?: string;
          };
          readonly " $fragmentSpreads": FragmentRefs<"oneSectionTopWidgetQueue" | "twoSectionTopWidgetQueue">;
        }>;
      } | null | undefined;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"subsectionMenuSection">;
  };
  readonly peopleCultureSection: {
    readonly name: string;
    readonly subSections: {
      readonly items: {
        readonly edges: ReadonlyArray<{
          readonly " $fragmentSpreads": FragmentRefs<"subsectionMenuQueue">;
        }>;
      } | null | undefined;
    } | null | undefined;
    readonly topStories: {
      readonly items: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly entityId?: string;
          };
          readonly " $fragmentSpreads": FragmentRefs<"oneSectionTopWidgetQueue" | "twoSectionTopWidgetQueue">;
        }>;
      } | null | undefined;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"subsectionMenuSection">;
  };
  readonly styleSection: {
    readonly name: string;
    readonly subSections: {
      readonly items: {
        readonly edges: ReadonlyArray<{
          readonly " $fragmentSpreads": FragmentRefs<"subsectionMenuQueue">;
        }>;
      } | null | undefined;
    } | null | undefined;
    readonly topStories: {
      readonly items: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly entityId?: string;
          };
          readonly " $fragmentSpreads": FragmentRefs<"oneSectionTopWidgetQueue" | "twoSectionTopWidgetQueue">;
        }>;
      } | null | undefined;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"subsectionMenuSection">;
  };
  readonly twiaSection?: {
    readonly name: string;
    readonly subSections: {
      readonly items: {
        readonly edges: ReadonlyArray<{
          readonly " $fragmentSpreads": FragmentRefs<"subsectionMenuQueue">;
        }>;
      } | null | undefined;
    } | null | undefined;
    readonly topStories: {
      readonly items: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly entityId?: string;
          };
          readonly " $fragmentSpreads": FragmentRefs<"oneSectionTopWidgetQueue" | "twoSectionTopWidgetQueue">;
        }>;
      } | null | undefined;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"subsectionMenuSection">;
  };
  readonly " $fragmentType": "sectionTopQuery";
};
export type sectionTopQuery$key = {
  readonly " $data"?: sectionTopQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"sectionTopQuery">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "subsectionMenuSection"
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "QueueItemsEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "subsectionMenuQueue"
      }
    ],
    "storageKey": null
  }
],
v3 = {
  "kind": "Literal",
  "name": "first",
  "value": 7
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Queue",
  "kind": "LinkedField",
  "name": "topStories",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "entityIds",
              "variableName": "excludeSsrContentEntityIds"
            }
          ],
          "kind": "ObjectValue",
          "name": "exclude"
        },
        (v3/*: any*/)
      ],
      "concreteType": "QueueItemConnection",
      "kind": "LinkedField",
      "name": "items",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "QueueItemsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "oneSectionTopWidgetQueue"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "twoSectionTopWidgetQueue"
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "entityId",
                      "storageKey": null
                    }
                  ],
                  "type": "Content",
                  "abstractKey": "__isContent"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = [
  (v0/*: any*/),
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "Queue",
    "kind": "LinkedField",
    "name": "subSections",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "first",
            "value": 5
          }
        ],
        "concreteType": "QueueItemConnection",
        "kind": "LinkedField",
        "name": "items",
        "plural": false,
        "selections": (v2/*: any*/),
        "storageKey": "items(first:5)"
      }
    ],
    "storageKey": null
  },
  (v4/*: any*/)
],
v6 = [
  (v0/*: any*/),
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "Queue",
    "kind": "LinkedField",
    "name": "subSections",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "first",
            "value": 6
          }
        ],
        "concreteType": "QueueItemConnection",
        "kind": "LinkedField",
        "name": "items",
        "plural": false,
        "selections": (v2/*: any*/),
        "storageKey": "items(first:6)"
      }
    ],
    "storageKey": null
  },
  (v4/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "excludeSsrContentEntityIds"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isAsiaEdition"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "sectionTopQuery",
  "selections": [
    {
      "alias": "chinaSection",
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "entityId": "4"
          }
        }
      ],
      "concreteType": "Section",
      "kind": "LinkedField",
      "name": "section",
      "plural": false,
      "selections": (v5/*: any*/),
      "storageKey": "section(filter:{\"entityId\":\"4\"})"
    },
    {
      "condition": "isAsiaEdition",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "alias": "hongKongSection",
          "args": [
            {
              "kind": "Literal",
              "name": "filter",
              "value": {
                "entityId": "2"
              }
            }
          ],
          "concreteType": "Section",
          "kind": "LinkedField",
          "name": "section",
          "plural": false,
          "selections": (v6/*: any*/),
          "storageKey": "section(filter:{\"entityId\":\"2\"})"
        }
      ]
    },
    {
      "alias": "lifestyleSection",
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "entityId": "94"
          }
        }
      ],
      "concreteType": "Section",
      "kind": "LinkedField",
      "name": "section",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Queue",
          "kind": "LinkedField",
          "name": "subSections",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "first",
                  "value": 4
                }
              ],
              "concreteType": "QueueItemConnection",
              "kind": "LinkedField",
              "name": "items",
              "plural": false,
              "selections": (v2/*: any*/),
              "storageKey": "items(first:4)"
            }
          ],
          "storageKey": null
        },
        (v4/*: any*/)
      ],
      "storageKey": "section(filter:{\"entityId\":\"94\"})"
    },
    {
      "alias": "peopleCultureSection",
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "entityId": "318202"
          }
        }
      ],
      "concreteType": "Section",
      "kind": "LinkedField",
      "name": "section",
      "plural": false,
      "selections": (v5/*: any*/),
      "storageKey": "section(filter:{\"entityId\":\"318202\"})"
    },
    {
      "alias": "styleSection",
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "entityId": "72"
          }
        }
      ],
      "concreteType": "Section",
      "kind": "LinkedField",
      "name": "section",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Queue",
          "kind": "LinkedField",
          "name": "subSections",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": [
                (v3/*: any*/)
              ],
              "concreteType": "QueueItemConnection",
              "kind": "LinkedField",
              "name": "items",
              "plural": false,
              "selections": (v2/*: any*/),
              "storageKey": "items(first:7)"
            }
          ],
          "storageKey": null
        },
        (v4/*: any*/)
      ],
      "storageKey": "section(filter:{\"entityId\":\"72\"})"
    },
    {
      "condition": "isAsiaEdition",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": "twiaSection",
          "args": [
            {
              "kind": "Literal",
              "name": "filter",
              "value": {
                "entityId": "323045"
              }
            }
          ],
          "concreteType": "Section",
          "kind": "LinkedField",
          "name": "section",
          "plural": false,
          "selections": (v6/*: any*/),
          "storageKey": "section(filter:{\"entityId\":\"323045\"})"
        }
      ]
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "050f38b64fdb9f49f3965aaf021f63ab";

export default node;
