import { type FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { BaseLinkContextProvider } from "scmp-app/components/common/base-link/context";
import { TopStoriesBlock } from "scmp-app/components/top-stories-block";
import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import { tracking } from "scmp-app/data";
import { getAbsoluteUrl } from "scmp-app/lib/utils";
import type { topStoriesQueueItemsEdge$key } from "scmp-app/queries/__generated__/topStoriesQueueItemsEdge.graphql";

import { useTopStoriesImpressionTracking } from "./hooks";
import { Container } from "./styles";

type Props = {
  adZone: string;
  className?: string;
  reference: topStoriesQueueItemsEdge$key;
};

export const HomeTopStories: FunctionComponent<Props> = ({ adZone, className, reference }) => {
  const items = useFragment(
    graphql`
      fragment topStoriesQueueItemsEdge on QueueItemsEdge @relay(plural: true) {
        node {
          ... on Content {
            entityId
          }
        }
        ...topStoriesBlockQueueItemsEdge
      }
    `,
    reference,
  );

  const listOfArticles = items?.map(item => item?.node?.entityId ?? "");
  const {
    captureTrackImpressionEventTargetFirstSetElement,
    captureTrackImpressionEventTargetFourthSetElement,
    captureTrackImpressionEventTargetSecondSetElement,
    captureTrackImpressionEventTargetThirdSetElement,
  } = useTopStoriesImpressionTracking(listOfArticles);

  if (!items) return null;

  const firstStoriesSet = items.slice(0, 4);
  const secondStoriesSet = items.slice(4, 8);
  const thirdStoriesSet = items.slice(8, 12);
  const fourthStoriesSet = items.slice(12);

  return (
    <BaseLinkContextProvider
      customQueryParameters={{
        module: tracking.module.TopStory,
      }}
    >
      <Container>
        <TopStoriesBlock
          adZone={adZone}
          className={className}
          isShowMLrecAd={true}
          onClick={(entityId, urlAlias, isSponsorArticle, index = 0) => {
            if (isSponsorArticle) {
              sendGA4Tracking({
                action: "click",
                category: "recirculation",
                customized_parameters: {
                  article_id: entityId,
                  destination_url: getAbsoluteUrl(urlAlias),
                  page_type: "homepage",
                  widget_name: "sponsor_article",
                },
                subcategory: "widget",
              });
            } else {
              sendGA4Tracking({
                action: "click",
                category: "recirculation",
                customized_parameters: {
                  article_id: entityId,
                  article_seq: `${index + 5}`,
                  destination_url: getAbsoluteUrl(urlAlias),
                  page_type: "homepage",
                  widget_name: "top_story5",
                },
                subcategory: "widget",
              });
            }
          }}
          ref={captureTrackImpressionEventTargetFirstSetElement}
          reference={firstStoriesSet}
          withCaption={false}
        />
        <TopStoriesBlock
          className={className}
          onClick={(entityId, urlAlias, _, index = 0) => {
            sendGA4Tracking({
              action: "click",
              category: "recirculation",
              customized_parameters: {
                article_id: entityId,
                article_seq: `${index + 8}`,
                destination_url: getAbsoluteUrl(urlAlias),
                page_type: "homepage",
                widget_name: "top_story8",
              },
              subcategory: "widget",
            });
          }}
          ref={captureTrackImpressionEventTargetSecondSetElement}
          reference={secondStoriesSet}
          withCaption={false}
        />
        <TopStoriesBlock
          className={className}
          onClick={(entityId, urlAlias, _, index = 0) => {
            sendGA4Tracking({
              action: "click",
              category: "recirculation",
              customized_parameters: {
                article_id: entityId,
                article_seq: `${index + 12}`,
                destination_url: getAbsoluteUrl(urlAlias),
                page_type: "homepage",
                widget_name: "top_story12",
              },
              subcategory: "widget",
            });
          }}
          ref={captureTrackImpressionEventTargetThirdSetElement}
          reference={thirdStoriesSet}
          withCaption={false}
        />
        <TopStoriesBlock
          adZone={adZone}
          className={className}
          isShowAds={true}
          onClick={(entityId, urlAlias, _, index = 0) => {
            sendGA4Tracking({
              action: "click",
              category: "recirculation",
              customized_parameters: {
                article_id: entityId,
                article_seq: `${index + 16}`,
                destination_url: getAbsoluteUrl(urlAlias),
                page_type: "homepage",
                widget_name: "top_story16",
              },
              subcategory: "widget",
            });
          }}
          ref={captureTrackImpressionEventTargetFourthSetElement}
          reference={fourthStoriesSet}
          withCaption={false}
        />
      </Container>
    </BaseLinkContextProvider>
  );
};

HomeTopStories.displayName = "HomeTopStories";
