/**
 * @generated SignedSource<<a66645ab7c6a896f354280dbf4f82c14>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type newsletterHomeNewsletterQuery$data = {
  readonly chinaAtAGlanceNewsletter: {
    readonly " $fragmentSpreads": FragmentRefs<"helpersMassageToNewsletterItemNewsletter">;
  };
  readonly hongKongUpdateNewsletter: {
    readonly " $fragmentSpreads": FragmentRefs<"helpersMassageToNewsletterItemNewsletter">;
  };
  readonly todayNewsletter: {
    readonly " $fragmentSpreads": FragmentRefs<"helpersMassageToNewsletterItemNewsletter">;
  };
  readonly " $fragmentType": "newsletterHomeNewsletterQuery";
};
export type newsletterHomeNewsletterQuery$key = {
  readonly " $data"?: newsletterHomeNewsletterQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"newsletterHomeNewsletterQuery">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "text",
    "storageKey": null
  }
],
v1 = [
  {
    "kind": "InlineDataFragmentSpread",
    "name": "helpersMassageToNewsletterItemNewsletter",
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "entityId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "alternativeName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "FormattedValue",
        "kind": "LinkedField",
        "name": "description",
        "plural": false,
        "selections": (v0/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "FormattedValue",
        "kind": "LinkedField",
        "name": "summary",
        "plural": false,
        "selections": (v0/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "FormattedValue",
        "kind": "LinkedField",
        "name": "homepageDescription",
        "plural": false,
        "selections": (v0/*: any*/),
        "storageKey": null
      }
    ],
    "args": null,
    "argumentDefinitions": ([]/*: any*/)
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "newsletterHomeNewsletterQuery",
  "selections": [
    {
      "alias": "chinaAtAGlanceNewsletter",
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "entityId": "322279"
          }
        }
      ],
      "concreteType": "Newsletter",
      "kind": "LinkedField",
      "name": "newsletter",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": "newsletter(filter:{\"entityId\":\"322279\"})"
    },
    {
      "alias": "hongKongUpdateNewsletter",
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "entityId": "510083"
          }
        }
      ],
      "concreteType": "Newsletter",
      "kind": "LinkedField",
      "name": "newsletter",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": "newsletter(filter:{\"entityId\":\"510083\"})"
    },
    {
      "alias": "todayNewsletter",
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "entityId": "322277"
          }
        }
      ],
      "concreteType": "Newsletter",
      "kind": "LinkedField",
      "name": "newsletter",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": "newsletter(filter:{\"entityId\":\"322277\"})"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "b0ddf685db396d4a78ddce0114b28962";

export default node;
