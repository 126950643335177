import { useTrackImpressionEventByIntersection } from "@product/scmp-sdk";
import last from "lodash/last";
import { type FunctionComponent, useCallback, useMemo } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";
import "swiper/css";
import "swiper/css/pagination";
import { Navigation } from "swiper/modules";

import { BaseLinkContextProvider } from "scmp-app/components/common/base-link/context";
import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import type { RecirculationWidgetImpressionEvent } from "scmp-app/components/tracking/google-analytics-4/types";
import { tracking } from "scmp-app/data";
import { Edition, useEditionValue } from "scmp-app/lib/edition";
import type {
  aroundHomeQuery,
  aroundHomeQuery$data,
} from "scmp-app/queries/__generated__/aroundHomeQuery.graphql";

import { HomeAroundItem } from "./around-item";
import {
  Container,
  Content,
  GridContainer,
  GridItem,
  StyledArrowNext,
  StyledArrowPrevious,
  StyledSwiper,
  StyledSwiperSlide,
  SwiperContainer,
  SwiperWrapper,
  Title,
} from "./styles";

type Props = {
  className?: string;
  existingEntityIds: string[];
};

export const HomeAround: FunctionComponent<Props> = ({ className, existingEntityIds }) => {
  // As this query is too large, better separate it out to prevent one slow query
  const data = useLazyLoadQuery<aroundHomeQuery>(
    graphql`
      query aroundHomeQuery($excludeEntityIds: [String]) {
        # Hong Kong
        section_top_2: queue(filter: { name: "section_top_2" }) {
          items(first: 6, exclude: { entityIds: $excludeEntityIds }) {
            edges {
              ...aroundItemQueueItemsEdge
              node {
                ... on Content {
                  entityId
                }
              }
            }
          }
        }
        # Asia
        section_top_3: queue(filter: { name: "section_top_3" }) {
          items(first: 6, exclude: { entityIds: $excludeEntityIds }) {
            edges {
              ...aroundItemQueueItemsEdge
              node {
                ... on Content {
                  entityId
                }
              }
            }
          }
        }
        # World
        section_top_5: queue(filter: { name: "section_top_5" }) {
          items(first: 6, exclude: { entityIds: $excludeEntityIds }) {
            edges {
              ...aroundItemQueueItemsEdge
              node {
                ... on Content {
                  entityId
                }
              }
            }
          }
        }
        # Business
        section_top_92: queue(filter: { name: "section_top_92" }) {
          items(first: 6, exclude: { entityIds: $excludeEntityIds }) {
            edges {
              ...aroundItemQueueItemsEdge
              node {
                ... on Content {
                  entityId
                }
              }
            }
          }
        }
        # Economy
        section_top_328461: queue(filter: { name: "section_top_328461" }) {
          items(first: 6, exclude: { entityIds: $excludeEntityIds }) {
            edges {
              ...aroundItemQueueItemsEdge
              node {
                ... on Content {
                  entityId
                }
              }
            }
          }
        }
        # Tech
        section_top_36: queue(filter: { name: "section_top_36" }) {
          items(first: 6, exclude: { entityIds: $excludeEntityIds }) {
            edges {
              ...aroundItemQueueItemsEdge
              node {
                ... on Content {
                  entityId
                }
              }
            }
          }
        }
        # Comment
        section_top_93: queue(filter: { name: "section_top_93" }) {
          items(first: 6, exclude: { entityIds: $excludeEntityIds }) {
            edges {
              ...aroundItemQueueItemsEdge
              node {
                ... on Content {
                  entityId
                }
              }
            }
          }
        }
        # Sport
        section_top_95: queue(filter: { name: "section_top_95" }) {
          items(first: 6, exclude: { entityIds: $excludeEntityIds }) {
            edges {
              ...aroundItemQueueItemsEdge
              node {
                ... on Content {
                  entityId
                }
              }
            }
          }
        }
        # Postmag
        section_top_71: queue(filter: { name: "section_top_71" }) {
          items(first: 6, exclude: { entityIds: $excludeEntityIds }) {
            edges {
              ...aroundItemQueueItemsEdge
              node {
                ... on Content {
                  entityId
                }
              }
            }
          }
        }
        # TWIA
        section_top_323045: queue(filter: { name: "section_top_323045" }) {
          items(first: 6, exclude: { entityIds: $excludeEntityIds }) {
            edges {
              ...aroundItemQueueItemsEdge
              node {
                ... on Content {
                  entityId
                }
              }
            }
          }
        }
        # Video Top
        video_homepage_top: queue(filter: { name: "video_homepage_top" }) {
          items(first: 6, exclude: { entityIds: $excludeEntityIds }) {
            edges {
              ...aroundItemQueueItemsEdge
              node {
                ... on Content {
                  entityId
                }
              }
            }
          }
        }
        # SCMP Cooking
        latest_recipe: queue(filter: { name: "latest_recipe" }) {
          items(first: 6, exclude: { entityIds: $excludeEntityIds }) {
            edges {
              ...aroundItemQueueItemsEdge
              node {
                ... on Content {
                  entityId
                }
              }
            }
          }
        }
        # KPOP
        section_top_507629: queue(filter: { name: "section_top_507629" }) {
          items(first: 6, exclude: { entityIds: $excludeEntityIds }) {
            edges {
              ...aroundItemQueueItemsEdge
              node {
                ... on Content {
                  entityId
                }
              }
            }
          }
        }
        # RECAP
        section_top_519775: queue(filter: { name: "section_top_519775" }) {
          items(first: 6, exclude: { entityIds: $excludeEntityIds }) {
            edges {
              ...aroundItemQueueItemsEdge
              node {
                ... on Content {
                  entityId
                }
              }
            }
          }
        }
        # MorningStudio
        homepage_morning_studio_int: queue(filter: { name: "homepage_morning_studio_int" }) {
          items(first: 4, exclude: { entityIds: $excludeEntityIds }) {
            edges {
              ...aroundItemQueueItemsEdge
              node {
                ... on Content {
                  entityId
                }
              }
            }
          }
        }
      }
    `,
    {
      excludeEntityIds: existingEntityIds,
    },
    {
      fetchPolicy: "store-or-network",
    },
  );
  const listMapping = useMemo<
    {
      name: string;
      queueName: keyof NonNullable<aroundHomeQuery$data>;
      urlAlias: string;
      withSCMPLabLogo?: boolean;
    }[]
  >(
    () => [
      {
        name: "HONG KONG",
        queueName: "section_top_2",
        urlAlias: "/news/hong-kong",
      },
      {
        name: "ASIA",
        queueName: "section_top_3",
        urlAlias: "/news/asia",
      },
      {
        name: "WORLD",
        queueName: "section_top_5",
        urlAlias: "/news/world",
      },
      {
        name: "BUSINESS",
        queueName: "section_top_92",
        urlAlias: "/business",
      },
      {
        name: "ECONOMY",
        queueName: "section_top_328461",
        urlAlias: "/economy",
      },
      {
        name: "TECH",
        queueName: "section_top_36",
        urlAlias: "/tech",
      },
      {
        name: "COMMENT",
        queueName: "section_top_93",
        urlAlias: "/comment",
      },
      {
        name: "SPORT",
        queueName: "section_top_95",
        urlAlias: "/sport",
      },
      {
        name: "POSTMAG",
        queueName: "section_top_71",
        urlAlias: "/postmag",
      },
      {
        name: "THIS WEEK IN ASIA",
        queueName: "section_top_323045",
        urlAlias: "/week-asia",
      },
      {
        name: "VIDEO",
        queueName: "video_homepage_top",
        urlAlias: "/video",
      },
      {
        name: "SCMP COOKING",
        queueName: "latest_recipe",
        urlAlias: "/cooking",
      },
      {
        name: "K-POP",
        queueName: "section_top_507629",
        urlAlias: "/k-pop",
      },
      {
        name: "RECAP",
        queueName: "section_top_519775",
        urlAlias: "/recap",
        withSCMPLabLogo: true,
      },
      {
        name: "MORNING STUDIO",
        queueName: "homepage_morning_studio_int",
        urlAlias: "https://morningstudio.scmp.com/",
      },
    ],
    [],
  );

  const edition = useEditionValue();

  const newList = useMemo(
    () =>
      listMapping.reduce(
        (accumulator, item) => {
          if (edition === Edition.Asia && item.name === "THIS WEEK IN ASIA") return accumulator;
          else if (edition !== Edition.Asia && item.name === "HONG KONG") return accumulator;
          const excludeEntityIds = last(accumulator)?.excludeEntityIds ?? [];
          const limit = item.name === "MORNING STUDIO" ? 2 : 3;
          const edges = data[item.queueName]?.items?.edges
            .filter(edge => !excludeEntityIds.includes(edge.node.entityId ?? ""))
            .slice(0, limit);
          if (!edges) return accumulator;
          accumulator.push({
            excludeEntityIds: [...excludeEntityIds, ...edges.map(edge => edge.node.entityId ?? "")],
            items: edges,
            name: item.name,
            urlAlias: item.urlAlias,
            withSCMPLabLogo: item.withSCMPLabLogo,
          });
          return accumulator;
        },
        [] as {
          excludeEntityIds: string[];
          items: NonNullable<
            NonNullable<aroundHomeQuery$data[keyof aroundHomeQuery$data]>["items"]
          >["edges"];
          name: string;
          urlAlias: string;
          withSCMPLabLogo?: boolean;
        }[],
      ),
    [data, edition, listMapping],
  );

  const renderSwiper = () => (
    <SwiperContainer>
      <SwiperWrapper>
        <StyledSwiper
          breakpoints={{
            0: {
              spaceBetween: 32,
            },
          }}
          freeMode={true}
          modules={[Navigation]}
          navigation={{
            disabledClass: "around-swiper-button-disabled",
            nextEl: ".around-swiper-button-next",
            prevEl: ".around-swiper-button-prev",
          }}
          pagination={false}
          slidesPerGroupAuto={true}
          slidesPerView="auto"
        >
          {newList.map(listItem => (
            <StyledSwiperSlide key={listItem.name}>
              <HomeAroundItem
                reference={listItem.items}
                title={listItem.name}
                urlAlias={listItem.urlAlias}
                withSCMPLabLogo={listItem.withSCMPLabLogo}
              />
            </StyledSwiperSlide>
          ))}
        </StyledSwiper>
        <div className="swiper-button around-swiper-button-prev">
          <StyledArrowPrevious />
        </div>
        <div className="swiper-button around-swiper-button-next">
          <StyledArrowNext />
        </div>
      </SwiperWrapper>
    </SwiperContainer>
  );

  const renderGrid = () => (
    <GridContainer>
      {newList.map(listItem => (
        <GridItem key={listItem.name}>
          <HomeAroundItem
            reference={listItem.items}
            title={listItem.name}
            urlAlias={listItem.urlAlias}
            withSCMPLabLogo={listItem.withSCMPLabLogo}
          />
        </GridItem>
      ))}
    </GridContainer>
  );

  const impressionGA4Event = useCallback<() => RecirculationWidgetImpressionEvent>(
    () => ({
      action: "imp",
      category: "recirculation",
      customized_parameters: {
        list_of_articles: newList.at(-1)?.excludeEntityIds.join(","),
        page_type: "homepage",
        widget_name: "around_scmp",
      },
      subcategory: "widget",
    }),
    [newList],
  );

  const { captureTrackImpressionEventTargetElement } =
    useTrackImpressionEventByIntersection<RecirculationWidgetImpressionEvent>({
      ga4TrackingHandler: sendGA4Tracking,
      getGa4Event: impressionGA4Event,
      options: { isSendGA4Tracking: true, shouldSendOnce: true },
    });

  return (
    <BaseLinkContextProvider
      customQueryParameters={{
        module: tracking.module.AroundSCMP,
      }}
    >
      <Container className={className} ref={captureTrackImpressionEventTargetElement}>
        <Title>AROUND SCMP</Title>
        <Content>
          {renderGrid()}
          {renderSwiper()}
        </Content>
      </Container>
    </BaseLinkContextProvider>
  );
};

HomeAround.displayName = "HomeAround";
