import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { BaseLinkContextProvider } from "scmp-app/components/common/base-link/context";
import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import { tracking } from "scmp-app/data";
import { getAbsoluteUrl } from "scmp-app/lib/utils";
import type { heroThreeArticlesQueueItemsEdge$key } from "scmp-app/queries/__generated__/heroThreeArticlesQueueItemsEdge.graphql";

import { Container, Item, StyledContentItemHomeSecondary } from "./styles";

type Props = {
  reference: heroThreeArticlesQueueItemsEdge$key;
};

export const HomeHeroThreeArticles: FunctionComponent<Props> = ({ reference }) => {
  const items = useFragment(
    graphql`
      fragment heroThreeArticlesQueueItemsEdge on QueueItemsEdge @relay(plural: true) {
        node {
          ... on Content {
            entityId
            ...homeSecondaryContentItemContent
          }
        }
      }
    `,
    reference,
  );

  if (!items) return null;

  return (
    <BaseLinkContextProvider
      customQueryParameters={{
        module: tracking.module.TopStory,
      }}
    >
      <Container>
        {items.map(({ node }, index) => (
          <Item key={index}>
            <StyledContentItemHomeSecondary
              key={node.entityId}
              onClick={(entityId, urlAlias) => {
                sendGA4Tracking({
                  action: "click",
                  category: "recirculation",
                  customized_parameters: {
                    article_id: entityId,
                    article_seq: `${index + 2}`,
                    destination_url: getAbsoluteUrl(urlAlias),
                    page_type: "homepage",
                    widget_name: "top_story1",
                  },
                  subcategory: "widget",
                });
              }}
              reference={node}
              withComment={true}
              withLiveTag={true}
              withSection={true}
              withSummary={true}
            />
          </Item>
        ))}
      </Container>
    </BaseLinkContextProvider>
  );
};

HomeHeroThreeArticles.displayName = "HomeHeroThreeArticles";
