import { scmpPlus } from "@product/scmp-sdk";
import sortBy from "lodash/sortBy";
import type { FunctionComponent } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";

import { ContentItemDailyFive } from "scmp-app/components/content/content-item-render/variants/home-daily-five";
import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import { getAbsoluteUrl } from "scmp-app/lib/utils";
import type { recommendArticlesQuery } from "scmp-app/queries/__generated__/recommendArticlesQuery.graphql";

import {
  ArticleNode,
  Container,
  MarigoldDot,
  SerialNumber,
  SerialNumberAndMarigoldDot,
} from "./styles";

type Props = {
  recommendedArticleIds?: string[];
};

export const RecommendArticles: FunctionComponent<Props> = ({ recommendedArticleIds }) => {
  const recommendArticlesResult = useLazyLoadQuery<recommendArticlesQuery>(
    graphql`
      query recommendArticlesQuery($entityIds: [String], $scmpPlusPaywallTypeIds: [String]) {
        articles(
          exclude: { paywallTypeIds: $scmpPlusPaywallTypeIds }
          filter: { entityIds: $entityIds }
          first: 5
        ) {
          edges {
            node {
              ...entityLink
              ...homeDailyFiveContentItemContent
              entityId
            }
          }
        }
      }
    `,
    {
      entityIds: recommendedArticleIds,
      scmpPlusPaywallTypeIds: [scmpPlus.article.paywallTypeId],
    },
    { fetchPolicy: "store-or-network" },
  );

  const articleNodes = recommendArticlesResult.articles.edges.map(edge => edge.node);

  const sortedArticleNodes = sortBy(articleNodes, node =>
    recommendedArticleIds?.indexOf(node.entityId),
  );

  return (
    <Container>
      {sortedArticleNodes.map((node, index) => (
        <ArticleNode key={node.entityId}>
          <SerialNumberAndMarigoldDot>
            <SerialNumber>{index + 1}</SerialNumber>
            <MarigoldDot />
          </SerialNumberAndMarigoldDot>
          <ContentItemDailyFive
            onClick={(entityId, urlAlias) => {
              sendGA4Tracking({
                action: "click",
                category: "mynews",
                customized_parameters: {
                  action_type: "read",
                  article_id: entityId,
                  position: index + 1,
                  trigger_point: "mynews_widget",
                },
                subcategory: "mydaily",
              });

              sendGA4Tracking({
                action: "click",
                category: "recirculation",
                customized_parameters: {
                  article_id: entityId,
                  destination_url: getAbsoluteUrl(urlAlias),
                  page_type: "homepage",
                  widget_name: "mynews",
                },
                subcategory: "widget",
              });
            }}
            reference={node}
          />
        </ArticleNode>
      ))}
    </Container>
  );
};

RecommendArticles.displayName = "RecommendArticles";
