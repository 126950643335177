import { type FunctionComponent } from "react";

import {
  DesktopContainer,
  MobileContainer,
  TabletContainer,
} from "scmp-app/components/home/placeholder";

import DesktopSectionPlaceholder from "./desktop-section-placeholder.svg";
import MobileSectionPlaceholder from "./mobile-section-placeholder.svg";
import TabletSectionPlaceholder from "./tablet-section-placeholder.svg";

type Props = {
  className?: string;
};

export const HomepageSectionPlaceholder: FunctionComponent<Props> = () => (
  <>
    <MobileContainer>
      <MobileSectionPlaceholder />
    </MobileContainer>
    <TabletContainer>
      <TabletSectionPlaceholder />
    </TabletContainer>
    <DesktopContainer>
      <DesktopSectionPlaceholder />
    </DesktopContainer>
  </>
);

HomepageSectionPlaceholder.displayName = "HomepageSectionPlaceholder";
