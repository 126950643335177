import type { FunctionComponent } from "react";

import { HomepageAiPlaceholder } from "scmp-app/components/home/placeholder/ai-placeholder";
import { HomepageAroundScmpPlaceholder } from "scmp-app/components/home/placeholder/around-scmp-placeholder";
import { HomepageFromOurAdvertisersPlaceholder } from "scmp-app/components/home/placeholder/from-our-advertisers-placeholder";
import { HomepageMostPopularPlaceholder } from "scmp-app/components/home/placeholder/most-popular-placeholder";
import { HomepageMultimediaPlaceholder } from "scmp-app/components/home/placeholder/multimedia-placeholder";
import { HomepageMyNewsPlaceholder } from "scmp-app/components/home/placeholder/my-news-placeholder";
import { HomepageSectionPlaceholder } from "scmp-app/components/home/placeholder/section-placeholder";
import { HomepageSubscriptionPlaceholder } from "scmp-app/components/home/placeholder/subscription-placeholder";
import {
  AroundScmp,
  FifthSectionBlock,
  FirstSectionBlock,
  FourthSectionBlock,
  FromOurAdvertisers,
  MostPopular,
  Multimedia,
  MyNews,
  RecommendedForYou,
  SecondSectionBlock,
  Subscription,
  ThirdSectionBlock,
  YouMayHaveMissed,
} from "scmp-app/pages/home/styles";

export { DesktopContainer, MobileContainer, TabletContainer } from "./styles";

export const HomepageClientQueryPlaceholder: FunctionComponent = () => (
  <>
    <Multimedia>
      <HomepageMultimediaPlaceholder />
    </Multimedia>
    <RecommendedForYou>
      <HomepageAiPlaceholder />
    </RecommendedForYou>
    <FirstSectionBlock>
      <HomepageSectionPlaceholder />
    </FirstSectionBlock>
    <Subscription>
      <HomepageSubscriptionPlaceholder />
    </Subscription>
    <SecondSectionBlock>
      <HomepageSectionPlaceholder />
    </SecondSectionBlock>
    <YouMayHaveMissed>
      <HomepageAiPlaceholder />
    </YouMayHaveMissed>
    <MyNews>
      <HomepageMyNewsPlaceholder />
    </MyNews>
    <ThirdSectionBlock>
      <HomepageSectionPlaceholder />
    </ThirdSectionBlock>
    <FourthSectionBlock>
      <HomepageSectionPlaceholder />
    </FourthSectionBlock>
    <FifthSectionBlock>
      <HomepageSectionPlaceholder />
    </FifthSectionBlock>
    <MostPopular>
      <HomepageMostPopularPlaceholder />
    </MostPopular>
    <FromOurAdvertisers>
      <HomepageFromOurAdvertisersPlaceholder />
    </FromOurAdvertisers>
    <AroundScmp>
      <HomepageAroundScmpPlaceholder />
    </AroundScmp>
  </>
);

HomepageClientQueryPlaceholder.displayName = "HomepageClientQueryPlaceholder";
