/**
 * @generated SignedSource<<c7893d8f7dd04cf930c1f102f5444a9e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type aroundItemQueueItemsEdge$data = ReadonlyArray<{
  readonly node: {
    readonly entityId?: string;
    readonly " $fragmentSpreads": FragmentRefs<"homeAroundMorningStudioPrimaryContentItemContent" | "homeAroundMorningStudioSecondaryContentItemContent" | "homeAroundPrimaryContentItemContent" | "homeAroundSecondaryContentItemContent">;
  };
  readonly " $fragmentType": "aroundItemQueueItemsEdge";
}>;
export type aroundItemQueueItemsEdge$key = ReadonlyArray<{
  readonly " $data"?: aroundItemQueueItemsEdge$data;
  readonly " $fragmentSpreads": FragmentRefs<"aroundItemQueueItemsEdge">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "aroundItemQueueItemsEdge",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "entityId",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "homeAroundPrimaryContentItemContent"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "homeAroundSecondaryContentItemContent"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "homeAroundMorningStudioPrimaryContentItemContent"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "homeAroundMorningStudioSecondaryContentItemContent"
            }
          ],
          "type": "Content",
          "abstractKey": "__isContent"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "QueueItemsEdge",
  "abstractKey": null
};

(node as any).hash = "1beff743d5c99748017511341aee638c";

export default node;
