import styled from "@emotion/styled";

import { ContentItemHomeSecondary } from "scmp-app/components/content/content-item-render/variants/home-secondary";
import { Summary } from "scmp-app/components/content/content-item-render/variants/home-secondary/styles";

export const StyledContentItemHomeSecondary = styled(ContentItemHomeSecondary)``;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${props => props.theme.breakpoints.up("tablet")} {
    flex-direction: row;
    gap: 32px;
  }
`;

export const Item = styled.div`
  border-block-end: 1px solid rgba(0, 0, 0, 0.1);

  padding-block-end: 20px;

  &:last-child {
    border-block-end: none;

    padding-block-end: 0;
  }
  :not(:last-of-type) {
    margin-block-end: 20px;
  }

  ${Summary} {
    display: none;

    ${props => props.theme.breakpoints.up("tablet")} {
      display: block;
    }
  }

  ${props => props.theme.breakpoints.up("tablet")} {
    flex: 1 1 33%;
    border-block-end: none;

    margin-block-end: 0;
    padding-block-end: 0;
  }
`;
