import type { ReactNode } from "react";
import { forwardRef } from "react";
import { graphql, useFragment } from "react-relay";

import { withHighlightedHeadline } from "scmp-app/components/content/content-item-render/highlight/hoc";
import { LinkHighlightArea } from "scmp-app/components/content/content-item-render/highlight/styles";
import { useContentItemProviders } from "scmp-app/components/content/content-item-render/hooks";
import type { ContentItemRenderVariantProps } from "scmp-app/components/content/content-item-render/types";
import type { homeStoryPackageLeadContentItemContent$key } from "scmp-app/queries/__generated__/homeStoryPackageLeadContentItemContent.graphql";

import {
  ActionBar,
  Container,
  Headline,
  HeadlineContainer,
  LeadingImage,
  Left,
  LiveTagContainer,
  Right,
  StyledEntityLink,
  Summary,
  TailContent,
} from "./styles";

export type Props = {
  children?: ReactNode;
  reference: homeStoryPackageLeadContentItemContent$key;
} & ContentItemRenderVariantProps;

const Component = forwardRef<HTMLDivElement, Props>(
  ({ children, className, onClick, reference: reference_ }, reference) => {
    const content = useFragment(
      graphql`
        fragment homeStoryPackageLeadContentItemContent on Content {
          entityId
          urlAlias
          ...entityLink
          ...hooksContentItemProvidersContent
            @arguments(
              withCoverImage: true
              withCoverImageLandscape250x99: true
              withCoverImageSize540x360: true
              withHeadline: true
              withLiveTag: true
              withSummary: true
              withActionBar: true
              withVideoDurationOverlay: true
            )
        }
      `,
      reference_,
    );
    const providers = useContentItemProviders(content);

    return (
      <Container className={className} ref={reference}>
        <LeadingImage>
          <StyledEntityLink
            onClick={() => onClick?.(content.entityId, content.urlAlias)}
            reference={content}
          >
            {providers.coverImage({
              responsiveVariants: {
                desktopUp: "landscape250x99",
                mobileUp: "size540x360",
                tabletUp: "landscape250x99",
              },
            })}
            {providers.video?.durationOverlay({
              iconSize: "large",
              variant: "homepage",
            })}
          </StyledEntityLink>
        </LeadingImage>
        <Left>
          <StyledEntityLink
            onClick={() => onClick?.(content.entityId, content.urlAlias)}
            reference={content}
          >
            <LinkHighlightArea>
              <HeadlineContainer>
                <LiveTagContainer>{providers.liveTag()}</LiveTagContainer>
                <Headline>
                  {providers.headline({ hideTooltip: true, preferSocialHeadline: true })}
                </Headline>
              </HeadlineContainer>
              <Summary>{providers.summary({ isTextOverflowEllipsis: true })}</Summary>
            </LinkHighlightArea>
          </StyledEntityLink>
          <ActionBar>
            {providers.actionBar({
              commentVariant: "reverse",
              isBookmarkVisible: false,
              isCommentsVisible: true,
            })}
          </ActionBar>
        </Left>
        <Right>
          <TailContent>{children}</TailContent>
        </Right>
      </Container>
    );
  },
);

Component.displayName = "ContentItemHomeStoryPackageLead";

export const ContentItemHomeStoryPackageLead = withHighlightedHeadline(Component);
