import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { Figure } from "scmp-app/components/content/content-cover-image/styles";
import { ContentItemHomeSecondary } from "scmp-app/components/content/content-item-render/variants/home-secondary";
import { Headline } from "scmp-app/components/content/content-item-render/variants/home-secondary/styles";
import { ContentItemSectionTopLeadSecondary } from "scmp-app/components/content/content-item-render/variants/section-top-lead-secondary";
import {
  Headline as LeadHeadline,
  LeadingImage,
} from "scmp-app/components/content/content-item-render/variants/section-top-lead-secondary/styles";
import { SectionLink } from "scmp-app/components/content/content-sections/styles";
import { EntityLink } from "scmp-app/components/entity-link";

const itemBorderStyle = css`
  margin-block-end: 20px;
  padding-block-end: 20px;
  border-block-end: 1px solid rgba(0, 0, 0, 0.1);
`;

export const StyledContentItemSectionTopLeadSecondary = styled(
  ContentItemSectionTopLeadSecondary,
)``;
export const StyledContentItemHomeSecondary = styled(ContentItemHomeSecondary)``;
export const StyledEntityLink = styled(EntityLink)`
  display: block;
`;

export const Container = styled.div`
  display: grid;
  grid:
    "primary" min-content
    "secondary" min-content
    / 1fr;

  ${props => props.theme.breakpoints.up("tablet")} {
    grid:
      "primary secondary" min-content
      / 1fr calc((100% - 64px) / 3);

    column-gap: 32px;
  }
`;

export const Primary = styled.div`
  grid-area: primary;

  ${props => props.theme.breakpoints.only("mobile")} {
    ${StyledContentItemSectionTopLeadSecondary} {
      ${itemBorderStyle}
    }
  }

  ${SectionLink} {
    color: #001246;
    font-size: 15px;
    line-height: normal;
  }

  ${LeadHeadline} {
    margin-block-end: 8px;

    font-size: 18px;
    line-height: 140%;

    ${props => props.theme.breakpoints.up("tablet")} {
      font-size: 22px;
    }
  }

  ${LeadingImage} {
    position: relative;

    margin-block-end: 12px;

    ${props => props.theme.breakpoints.up("tablet")} {
      margin-block-end: 20px;
    }
    ${Figure} {
      aspect-ratio: 3/2;
    }
  }
`;

export const Secondary = styled.div`
  grid-area: secondary;

  ${StyledContentItemHomeSecondary}:not(:last-child) {
    ${itemBorderStyle}
  }

  ${Headline} {
    font-size: 15px;
    line-height: 140%;
  }
`;
