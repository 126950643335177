import { type FunctionComponent } from "react";

import {
  DesktopContainer,
  MobileContainer,
  TabletContainer,
} from "scmp-app/components/home/placeholder";

import DesktopFromOurAdvertisersPlaceholder from "./desktop-from-our-advertisers-placeholder.svg";
import MobileFromOurAdvertisersPlaceholder from "./mobile-from-our-advertisers-placeholder.svg";
import TabletFromOurAdvertisersPlaceholder from "./tablet-from-our-advertisers-placeholder.svg";

type Props = {
  className?: string;
};

export const HomepageFromOurAdvertisersPlaceholder: FunctionComponent<Props> = () => (
  <>
    <MobileContainer>
      <MobileFromOurAdvertisersPlaceholder />
    </MobileContainer>
    <TabletContainer>
      <TabletFromOurAdvertisersPlaceholder />
    </TabletContainer>
    <DesktopContainer>
      <DesktopFromOurAdvertisersPlaceholder />
    </DesktopContainer>
  </>
);

HomepageFromOurAdvertisersPlaceholder.displayName = "HomepageFromOurAdvertisersPlaceholder";
