import { FollowType, useTrackImpressionEventByIntersection } from "@product/scmp-sdk";
import { type FunctionComponent, useCallback } from "react";
import { graphql, useFragment } from "react-relay";

import { BaseLinkContextProvider } from "scmp-app/components/common/base-link/context";
import { StyledSwiperSlide } from "scmp-app/components/entity-oneline-menu/styles";
import {
  StyledTopicFollowButton,
  TopicName,
  TopicNameEntityLink,
} from "scmp-app/components/home/story-package/styles";
import { SectionTermLogo } from "scmp-app/components/home/term-logo/section";
import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import type { RecirculationWidgetImpressionEvent } from "scmp-app/components/tracking/google-analytics-4/types";
import { tracking } from "scmp-app/data";
import { getAbsoluteUrl } from "scmp-app/lib/utils";
import type { chinaFutureTechStoryPackageQuery$key } from "scmp-app/queries/__generated__/chinaFutureTechStoryPackageQuery.graphql";

import { Container, StyledEntityOnelineMenu, StyledTopStoriesBlock, TopicBadge } from "./styles";

type Settings = {
  custom_text?: string;
};

type Props = {
  className?: string;
  reference: chinaFutureTechStoryPackageQuery$key;
};

export const HomeChinaFutureTechStoryPackage: FunctionComponent<Props> = ({
  className,
  reference: reference_,
}) => {
  const data = useFragment(
    graphql`
      fragment chinaFutureTechStoryPackageQuery on Query
      @argumentDefinitions(sectionEntityId: { type: "String!" }, queueName: { type: "String!" }) {
        section(filter: { entityId: $sectionEntityId }) {
          name
          ...entityLink
          ...followButtonBase
          ...sectionTermLogoSection
          subSections {
            settings
            items {
              edges {
                ...entityOnelineMenuQueueItemsEdge
              }
            }
          }
          __typename
        }
        chinaFutureTechStoryPackageArticles: queue(filter: { name: $queueName }) {
          settings
          items(first: 4) {
            edges {
              node {
                ... on Content {
                  entityId
                }
              }
              ...topStoriesBlockQueueItemsEdge
            }
          }
        }
      }
    `,
    reference_,
  );

  const customText = (data?.chinaFutureTechStoryPackageArticles?.settings as Settings)?.custom_text;
  const displayName = customText === "" ? data?.section?.name : customText;

  const secondaryStoryPackageItems = data.chinaFutureTechStoryPackageArticles?.items?.edges ?? [];
  const listOfArticles = (
    data?.chinaFutureTechStoryPackageArticles?.items?.edges?.map(item => item.node.entityId) ?? []
  ).join(",");

  const impressionGA4Event = useCallback<() => RecirculationWidgetImpressionEvent>(
    () => ({
      action: "imp",
      category: "recirculation",
      customized_parameters: {
        block_section: displayName,
        list_of_articles: listOfArticles,
        page_type: "homepage",
        widget_name: "china_future_tech",
      },
      subcategory: "widget",
    }),
    [displayName, listOfArticles],
  );

  const { captureTrackImpressionEventTargetElement } =
    useTrackImpressionEventByIntersection<RecirculationWidgetImpressionEvent>({
      ga4TrackingHandler: sendGA4Tracking,
      getGa4Event: impressionGA4Event,
      options: { isSendGA4Tracking: true, shouldSendOnce: true },
    });

  const sectionCategoriesCustomText = (data.section?.subSections?.settings as Settings)
    ?.custom_text;

  return (
    <BaseLinkContextProvider
      customQueryParameters={{
        module: tracking.module.ChinaFutureTech,
      }}
    >
      <Container className={className} ref={captureTrackImpressionEventTargetElement}>
        <TopicBadge>
          <SectionTermLogo reference={data?.section} />
          <TopicNameEntityLink reference={data?.section}>
            <TopicName>{displayName}</TopicName>
          </TopicNameEntityLink>
          <StyledTopicFollowButton
            reference={data?.section}
            source="china_future_tech"
            type={FollowType.Section}
          />
        </TopicBadge>

        <StyledEntityOnelineMenu
          extraSlide={<StyledSwiperSlide>{sectionCategoriesCustomText}</StyledSwiperSlide>}
          reference={data.section.subSections?.items?.edges ?? []}
        />

        <StyledTopStoriesBlock
          onClick={(entityId, urlAlias) => {
            sendGA4Tracking({
              action: "click",
              category: "recirculation",
              customized_parameters: {
                article_id: entityId,
                block_section: displayName,
                destination_url: getAbsoluteUrl(urlAlias),
                page_type: "homepage",
                widget_name: "china_future_tech",
              },
              subcategory: "widget",
            });
          }}
          reference={secondaryStoryPackageItems}
          withCaption={false}
        />
      </Container>
    </BaseLinkContextProvider>
  );
};

HomeChinaFutureTechStoryPackage.displayName = "HomeChinaFutureTechStoryPackage";
