/**
 * @generated SignedSource<<14f89e8655d9f065d36651bb77d82b6f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type heroThreeArticlesQueueItemsEdge$data = ReadonlyArray<{
  readonly node: {
    readonly entityId?: string;
    readonly " $fragmentSpreads": FragmentRefs<"homeSecondaryContentItemContent">;
  };
  readonly " $fragmentType": "heroThreeArticlesQueueItemsEdge";
}>;
export type heroThreeArticlesQueueItemsEdge$key = ReadonlyArray<{
  readonly " $data"?: heroThreeArticlesQueueItemsEdge$data;
  readonly " $fragmentSpreads": FragmentRefs<"heroThreeArticlesQueueItemsEdge">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "heroThreeArticlesQueueItemsEdge",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "entityId",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "homeSecondaryContentItemContent"
            }
          ],
          "type": "Content",
          "abstractKey": "__isContent"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "QueueItemsEdge",
  "abstractKey": null
};

(node as any).hash = "850b14e6c7ef6b44eb433236021429b7";

export default node;
