import styled from "@emotion/styled";
import { fontMerriweather } from "@product/scmp-sdk";

import { EntityLink } from "scmp-app/components/entity-link";

export const Container = styled.div``;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  color: #000000;
`;

export const StyledEntityLink = styled(EntityLink)``;

export const Headline = styled.div`
  color: #000000;
  font-weight: 700;
  font-size: 15px;
  font-family: ${fontMerriweather};
  line-height: 21px;
`;

export const ActionBar = styled.div`
  time {
    color: #999999;
    font-size: 12px;
    line-height: 14px;
  }
`;

export const HeadlineContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LiveTagContainer = styled.div`
  margin-block-end: 8px;

  :empty {
    margin-block-end: 0;
  }
`;
