import { type FunctionComponent } from "react";

import {
  DesktopContainer,
  MobileContainer,
  TabletContainer,
} from "scmp-app/components/home/placeholder";

import DesktopMultimediaPlaceholder from "./desktop-multimedia-placeholder.svg";
import MobileMultimediaPlaceholder from "./mobile-multimedia-placeholder.svg";
import TabletMultimediaPlaceholder from "./tablet-multimedia-placeholder.svg";

type Props = {
  className?: string;
};

export const HomepageMultimediaPlaceholder: FunctionComponent<Props> = () => (
  <>
    <MobileContainer>
      <MobileMultimediaPlaceholder />
    </MobileContainer>
    <TabletContainer>
      <TabletMultimediaPlaceholder />
    </TabletContainer>
    <DesktopContainer>
      <DesktopMultimediaPlaceholder />
    </DesktopContainer>
  </>
);

HomepageMultimediaPlaceholder.displayName = "HomepageMultimediaPlaceholder";
