import { type FunctionComponent } from "react";

import {
  DesktopContainer,
  MobileContainer,
  TabletContainer,
} from "scmp-app/components/home/placeholder";

import DesktopAroundScmpPlaceholder from "./desktop-around-scmp-placeholder.svg";
import MobileAroundScmpPlaceholder from "./mobile-around-scmp-placeholder.svg";
import TabletAroundScmpPlaceholder from "./tablet-around-scmp-placeholder.svg";

type Props = {
  className?: string;
};

export const HomepageAroundScmpPlaceholder: FunctionComponent<Props> = () => (
  <>
    <MobileContainer>
      <MobileAroundScmpPlaceholder />
    </MobileContainer>
    <TabletContainer>
      <TabletAroundScmpPlaceholder />
    </TabletContainer>
    <DesktopContainer>
      <DesktopAroundScmpPlaceholder />
    </DesktopContainer>
  </>
);

HomepageAroundScmpPlaceholder.displayName = "HomepageAroundScmpPlaceholder";
