import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { fontRobotoCondensed, theme } from "@product/scmp-sdk";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";

import ArrowNext from "./arrow-next.svg";

export const Container = styled.div`
  padding-block: 16px 32px;
  border-block-start: 1px solid #000000;
  ${props => props.theme.breakpoints.up("desktop")} {
    padding-block-end: 48px;
  }
`;

export const Title = styled.div`
  margin-block-end: 20px;

  color: rgb(0, 0, 0, 0.5);
  font-size: 20px;
  font-family: ${fontRobotoCondensed};
  line-height: 23px;

  ${props => props.theme.breakpoints.up("desktop")} {
    margin-block-end: 24px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
`;

export const GridContainer = styled.div`
  display: none;
  ${props => props.theme.breakpoints.up("desktop")} {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 32px;
  }
`;

export const GridItem = styled.div``;

export const SwiperContainer = styled.div`
  inline-size: 100%;
  ${theme.breakpoints.up("desktop")} {
    display: none;
  }
`;

export const SwiperWrapper = styled.div`
  position: relative;

  overflow: visible;
  &:hover {
    .around-swiper-button-prev svg,
    .around-swiper-button-next svg {
      opacity: 1;
    }
  }

  .around-swiper-button-prev svg,
  .around-swiper-button-next svg {
    opacity: 0;

    transition: opacity 1s;
  }

  .around-swiper-button-disabled {
    opacity: 0;
  }
`;

export const StyledSwiper = styled(Swiper)`
  block-size: auto;

  overflow-y: hidden;

  .swiper-slide {
    block-size: auto;
  }
`;

export const StyledSwiperSlide = styled(SwiperSlide)`
  max-inline-size: 234px;
`;

const ArrowStyle = css`
  inset-block-start: 50%;

  display: none;

  ${theme.breakpoints.up("tablet")} {
    position: absolute;
    z-index: 1;

    display: block;

    cursor: pointer;
  }
`;

export const StyledArrowNext = styled(ArrowNext)`
  ${ArrowStyle}

  transform: translateY(-50%);
  ${theme.breakpoints.up("tablet")} {
    inset-inline-end: -8px;
  }
  ${theme.breakpoints.up("desktop")} {
    flex-direction: row;
    align-items: flex-start;
  }
`;

export const StyledArrowPrevious = styled(ArrowNext)`
  ${ArrowStyle}

  transform: translateY(-50%) rotate(180deg);
  ${theme.breakpoints.up("tablet")} {
    inset-inline-start: -8px;
  }
  ${theme.breakpoints.up("desktop")} {
    flex-direction: row-reverse;
    align-items: flex-start;
  }
`;
