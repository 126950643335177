import { useTrackImpressionEventByIntersection } from "@product/scmp-sdk";
import { useCallback } from "react";

import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import type { RecirculationWidgetImpressionEvent } from "scmp-app/components/tracking/google-analytics-4/types";

export const commentHash = "#comments";

export const useTopStoriesImpressionTracking = (listOfArticles: string[]) => {
  const firstImpressionGA4Event = useCallback<() => RecirculationWidgetImpressionEvent>(
    () => ({
      action: "imp",
      category: "recirculation",
      customized_parameters: {
        list_of_articles: listOfArticles.slice(0, 4).join(","),
        page_type: "homepage",
        widget_name: "top_story5",
      },
      subcategory: "widget",
    }),
    [listOfArticles],
  );
  const {
    captureTrackImpressionEventTargetElement: captureTrackImpressionEventTargetFirstSetElement,
  } = useTrackImpressionEventByIntersection<RecirculationWidgetImpressionEvent>({
    ga4TrackingHandler: sendGA4Tracking,
    getGa4Event: firstImpressionGA4Event,
    options: { isSendGA4Tracking: true, shouldSendOnce: true },
  });

  const secondImpressionGA4Event = useCallback<() => RecirculationWidgetImpressionEvent>(
    () => ({
      action: "imp",
      category: "recirculation",
      customized_parameters: {
        list_of_articles: listOfArticles.slice(4, 8).join(","),
        page_type: "homepage",
        widget_name: "top_story8",
      },
      subcategory: "widget",
    }),
    [listOfArticles],
  );
  const {
    captureTrackImpressionEventTargetElement: captureTrackImpressionEventTargetSecondSetElement,
  } = useTrackImpressionEventByIntersection<RecirculationWidgetImpressionEvent>({
    ga4TrackingHandler: sendGA4Tracking,
    getGa4Event: secondImpressionGA4Event,
    options: { isSendGA4Tracking: true, shouldSendOnce: true },
  });

  const thirdImpressionGA4Event = useCallback<() => RecirculationWidgetImpressionEvent>(
    () => ({
      action: "imp",
      category: "recirculation",
      customized_parameters: {
        list_of_articles: listOfArticles.slice(8, 12).join(","),
        page_type: "homepage",
        widget_name: "top_story12",
      },
      subcategory: "widget",
    }),
    [listOfArticles],
  );
  const {
    captureTrackImpressionEventTargetElement: captureTrackImpressionEventTargetThirdSetElement,
  } = useTrackImpressionEventByIntersection<RecirculationWidgetImpressionEvent>({
    ga4TrackingHandler: sendGA4Tracking,
    getGa4Event: thirdImpressionGA4Event,
    options: { isSendGA4Tracking: true, shouldSendOnce: true },
  });

  const fourthImpressionGA4Event = useCallback<() => RecirculationWidgetImpressionEvent>(
    () => ({
      action: "imp",
      category: "recirculation",
      customized_parameters: {
        list_of_articles: listOfArticles.slice(12).join(","),
        page_type: "homepage",
        widget_name: "top_story16",
      },
      subcategory: "widget",
    }),
    [listOfArticles],
  );
  const {
    captureTrackImpressionEventTargetElement: captureTrackImpressionEventTargetFourthSetElement,
  } = useTrackImpressionEventByIntersection<RecirculationWidgetImpressionEvent>({
    ga4TrackingHandler: sendGA4Tracking,
    getGa4Event: fourthImpressionGA4Event,
    options: { isSendGA4Tracking: true, shouldSendOnce: true },
  });

  return {
    captureTrackImpressionEventTargetFirstSetElement,
    captureTrackImpressionEventTargetFourthSetElement,
    captureTrackImpressionEventTargetSecondSetElement,
    captureTrackImpressionEventTargetThirdSetElement,
  };
};
