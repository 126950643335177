import styled from "@emotion/styled";
import { fontMerriweather, fontRobotoCondensed } from "@product/scmp-sdk";

import { SponsorHeadline } from "scmp-app/components/content/content-headline/styles";
import { ContentItemHomeSecondary } from "scmp-app/components/content/content-item-render/variants/home-secondary";
import {
  ActionBar,
  Headline,
} from "scmp-app/components/content/content-item-render/variants/home-secondary/styles";
import { HomeSponsorTagContainer } from "scmp-app/components/sponsor-tag/styles";
import { Container as TrendingTopicWidgetContainer } from "scmp-app/components/trending-topic/styles";

export const StyledContentItemHomeSecondary = styled(ContentItemHomeSecondary)``;

export const Title = styled.div`
  margin-block-end: 20px;

  color: #00000080;
  font-size: 20px;
  font-family: ${fontRobotoCondensed};
`;

export const MostPopularItems = styled.div`
  display: flex;
  flex-flow: column nowrap;

  ${props => props.theme.breakpoints.between("tablet", "desktop")} {
    flex-flow: row nowrap;
    gap: 32px;
  }
`;

export const TrendingTopicContainer = styled.div`
  margin-block-start: 32px;
  padding-block-start: 20px;
  border-block-start: 1px solid #000000;

  ${props => props.theme.breakpoints.between("tablet", "desktop")} {
    padding-block-start: 16px;
  }
`;

export const MostPopularItem = styled.div`
  display: flex;
  flex: 1;
  flex-flow: row nowrap;
  gap: 12px;
  align-items: center;

  ${props => props.theme.breakpoints.between("tablet", "desktop")} {
    flex-flow: column nowrap;
    align-items: flex-start;
  }

  ${Headline}, ${SponsorHeadline} {
    margin-block-end: 0;

    font-weight: 400;
    font-size: 15px;
    font-family: ${fontMerriweather};
    line-height: 21px;
  }

  ${ActionBar}, ${HomeSponsorTagContainer} {
    display: none;
  }
`;

export const DisplayNumber = styled.div`
  color: #4585ff;
  font-weight: 300;
  font-size: 48px;
  font-family: ${fontRobotoCondensed};
  line-height: 56px;
`;

export const Container = styled.div`
  padding: 20px;

  border-radius: 8px;

  outline: 1px solid #00000033;

  ${MostPopularItem}:not(:first-child) {
    margin-block-start: 20px;
    padding-block-start: 20px;
    border-block-start: 1px solid rgba(0, 0, 0, 0.1);

    ${props => props.theme.breakpoints.between("tablet", "desktop")} {
      margin-block-start: 0;
      padding-block-start: 0;
      border-block-start: none;
    }
  }

  ${TrendingTopicWidgetContainer} {
    padding: 0;

    border: none;
  }
`;

export const TrendingTopics = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
`;
