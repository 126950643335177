import styled from "@emotion/styled";
import { fontRobotoCondensed } from "@product/scmp-sdk";

import { LiveTag } from "scmp-app/components/content/content-live-tag/styles";
import { SectionContainer } from "scmp-app/components/content/content-sections/styles";
import { EntityOnelineMenu } from "scmp-app/components/entity-oneline-menu";
import { MenuItem } from "scmp-app/components/entity-oneline-menu/styles";
import { TopStoriesBlock } from "scmp-app/components/top-stories-block";

export const Container = styled.div`
  display: grid;
  grid:
    "topic-name-follow" min-content
    "subsection" min-content
    "home-top-stories-block" min-content
    / 100%;

  margin-block-start: 32px;
  padding-block-start: 16px;

  border-block-start: 1px solid #000000;

  ${props => props.theme.breakpoints.up("tablet")} {
    grid:
      "topic-name-follow subsection" min-content
      "home-top-stories-block home-top-stories-block" min-content
      / max-content minmax(0, 1fr);

    margin-block-start: 48px;
  }
`;

export const TopicBadge = styled.div`
  grid-area: topic-name-follow;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  block-size: fit-content;
  margin-block-end: 20px;

  text-transform: uppercase;

  ${props => props.theme.breakpoints.up("tablet")} {
    margin-block-end: 24px;
  }
`;

export const StyledEntityOnelineMenu = styled(EntityOnelineMenu)`
  display: flex;
  align-items: center;

  margin-block-end: 20px;
  ${props => props.theme.breakpoints.up("desktop")} {
    margin-block-end: 24px;
  }

  .swiper {
    margin-inline-start: 0;
    ${props => props.theme.breakpoints.up("tablet")} {
      margin-inline-start: 20px;
    }
  }

  .swiper-slide {
    font-size: 14px;
    font-family: ${fontRobotoCondensed};
    line-height: 140%;
  }

  .swiper-slide:not(:last-child) {
    margin-inline-end: 16px !important;
  }

  .swiper-slide:first-child {
    font-weight: 700;
  }

  ${MenuItem} {
    font-family: ${fontRobotoCondensed};
  }
`;

export const StyledTopStoriesBlock = styled(TopStoriesBlock)`
  grid-area: home-top-stories-block;

  ${LiveTag} {
    display: none;
  }
  ${SectionContainer} {
    display: block;
  }
`;
