import orderBy from "lodash/orderBy";
import { type FunctionComponent } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";

import { formatDistanceToNow, toISOString } from "scmp-app/lib/utils";
import type { timelinesInSuperSplashLiveQuery } from "scmp-app/queries/__generated__/timelinesInSuperSplashLiveQuery.graphql";

import { Container, DateContainer, SnippetTitle, Timeline } from "./styles";

type Props = {
  className?: string;
  entityUuid: string;
  urlAlias: string;
};

export const HomeTimelines: FunctionComponent<Props> = ({ className, entityUuid, urlAlias }) => {
  const data = useLazyLoadQuery<timelinesInSuperSplashLiveQuery>(
    graphql`
      query timelinesInSuperSplashLiveQuery($entityUuid: String!) {
        liveArticle(filter: { entityUuid: $entityUuid }) {
          liveContents {
            entityId
            title
            publishedDate
          }
        }
      }
    `,
    {
      entityUuid,
    },
    { fetchPolicy: "store-or-network" },
  );

  const handleRenderDisplayDateTime = (displayDateTime: number) => {
    if (displayDateTime === 0) return null;
    return (
      <time dateTime={toISOString(displayDateTime)} suppressHydrationWarning>
        {formatDistanceToNow(displayDateTime)}
      </time>
    );
  };

  const slicedLiveContents = orderBy(
    data?.liveArticle?.liveContents,
    "publishedDate",
    "desc",
  )?.slice(0, 5);

  return (
    <Container className={className}>
      {slicedLiveContents?.map((liveSnippet, key) => {
        if (liveSnippet) {
          return (
            <Timeline hash={`#live-${liveSnippet.entityId}`} key={key} pathname={urlAlias}>
              <DateContainer>
                {handleRenderDisplayDateTime(liveSnippet?.publishedDate ?? 0)}
              </DateContainer>
              <SnippetTitle>{liveSnippet.title}</SnippetTitle>
            </Timeline>
          );
        }
      })}
    </Container>
  );
};

HomeTimelines.displayName = "HomeTimelines";
