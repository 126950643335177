import styled from "@emotion/styled";
import { fontMerriweather, fontRobotoCondensed } from "@product/scmp-sdk";

import { BaseLink } from "scmp-app/components/common/base-link";
import { ContentItemHomeSecondary } from "scmp-app/components/content/content-item-render/variants/home-secondary";
import {
  ActionBar,
  CoverImage,
  Headline,
  StyledCoverEntityLink,
} from "scmp-app/components/content/content-item-render/variants/home-secondary/styles";

export const StyledContentItemHomeSecondary = styled(ContentItemHomeSecondary)`
  display: flex;
  flex-direction: column;
`;

export const TitleBasLink = styled(BaseLink)`
  margin-block-end: 20px;
`;

export const Title = styled.div`
  color: #00000080;
  font-size: 20px;
  font-family: ${fontRobotoCondensed};
`;

export const ThingsToDoItems = styled.div`
  display: grid;
  grid-row-gap: 20px;

  ${Headline} {
    margin-block-end: 8px;

    font-weight: 400;
    font-size: 15px;
    font-family: ${fontMerriweather};
    font-style: normal;
    line-height: 21px;

    ${props => props.theme.breakpoints.up("tablet")} {
      margin-block-end: 12px;

      font-size: 15px;
      line-height: 21px;
    }
  }

  ${CoverImage} {
    inline-size: 100%;
    block-size: 100%;
    aspect-ratio: 3/2;
  }

  ${ActionBar} {
    display: none;
  }

  ${StyledCoverEntityLink} {
    display: block;

    margin-block-start: 0;
    ${props => props.theme.breakpoints.up("tablet")} {
      margin-block-start: auto;
    }
  }

  ${props => props.theme.breakpoints.up("tablet")} {
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 20px;
  }
  ${props => props.theme.breakpoints.up("desktop")} {
    grid-template-columns: 1fr;
  }
`;

export const Container = styled.div`
  position: relative;

  padding: 20px;

  &::after {
    position: absolute;
    inset-block-start: 0;
    inset-inline-start: 0;
    content: "";
    z-index: -1;

    inline-size: 100%;
    block-size: 100%;

    border-radius: 8px;

    outline: 1px solid #00000033;
  }

  ${StyledContentItemHomeSecondary}:not(:last-child) {
    padding-block-end: 20px;
    border-block-end: 1px solid #0000001a;

    ${props => props.theme.breakpoints.up("tablet")} {
      border-block-end: none;

      padding-block-end: 0;
    }
    ${props => props.theme.breakpoints.up("desktop")} {
      border-block-end: 1px solid #0000001a;

      padding-block-end: 20px;
    }
  }
`;
