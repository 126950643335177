import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { Logo } from "scmp-app/components/home/term-logo/styles";
import type { topicTermLogoTopic$key } from "scmp-app/queries/__generated__/topicTermLogoTopic.graphql";

type Props = {
  reference: topicTermLogoTopic$key;
};

export const TopicTermLogo: FunctionComponent<Props> = ({ reference }) => {
  const data = useFragment(
    graphql`
      fragment topicTermLogoTopic on Topic {
        name
        image(type: TERM_LOGO) {
          style(filter: { style: "100x100" }) {
            url
          }
        }
      }
    `,
    reference,
  );

  if (!data.image?.style?.url) return null;
  return <Logo alt={data.name} src={data.image.style.url} />;
};

TopicTermLogo.displayName = "TopicTermLogo";
