/**
 * @generated SignedSource<<56e8aef5647501a7b379b193bbf95cd8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type topicTermLogoTopic$data = {
  readonly image: {
    readonly style: {
      readonly url: string | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly name: string;
  readonly " $fragmentType": "topicTermLogoTopic";
};
export type topicTermLogoTopic$key = {
  readonly " $data"?: topicTermLogoTopic$data;
  readonly " $fragmentSpreads": FragmentRefs<"topicTermLogoTopic">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "topicTermLogoTopic",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "type",
          "value": "TERM_LOGO"
        }
      ],
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "image",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "filter",
              "value": {
                "style": "100x100"
              }
            }
          ],
          "concreteType": "ImageStyle",
          "kind": "LinkedField",
          "name": "style",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "url",
              "storageKey": null
            }
          ],
          "storageKey": "style(filter:{\"style\":\"100x100\"})"
        }
      ],
      "storageKey": "image(type:\"TERM_LOGO\")"
    }
  ],
  "type": "Topic",
  "abstractKey": null
};

(node as any).hash = "55feabe49dfd60c8f25a6bb2b7fed129";

export default node;
