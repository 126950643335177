/**
 * @generated SignedSource<<5b8c790328013b1e3f1653c545e43c66>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type homeSuperSplashLivePrimaryContentItemContent$data = {
  readonly entityId: string;
  readonly " $fragmentSpreads": FragmentRefs<"entityLink" | "hooksContentItemProvidersContent">;
  readonly " $fragmentType": "homeSuperSplashLivePrimaryContentItemContent";
};
export type homeSuperSplashLivePrimaryContentItemContent$key = {
  readonly " $data"?: homeSuperSplashLivePrimaryContentItemContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"homeSuperSplashLivePrimaryContentItemContent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "homeSuperSplashLivePrimaryContentItemContent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entityId",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "entityLink"
    },
    {
      "args": [
        {
          "kind": "Literal",
          "name": "withActionBar",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withCoverImage",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withCoverImageSize1200x800",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withCoverImageSize540x360",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withHeadline",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withLiveTag",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withSubHeadline",
          "value": true
        }
      ],
      "kind": "FragmentSpread",
      "name": "hooksContentItemProvidersContent"
    }
  ],
  "type": "Content",
  "abstractKey": "__isContent"
};

(node as any).hash = "39c8105cfe2b38498f6eb19bab8f7678";

export default node;
