import first from "lodash/first";
import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { useArticleDateUtils } from "scmp-app/components/article/article-date/hooks";
import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import type { plusWidgetDailyPulseQuery$key } from "scmp-app/queries/__generated__/plusWidgetDailyPulseQuery.graphql";

import {
  Container,
  LastUpdatedDate,
  ReadFullDailyPulseLink,
  StyledContentHeadline,
} from "./styles";

type Props = {
  className?: string;
  pageType: "homepage" | "section" | "topic";
  reference: plusWidgetDailyPulseQuery$key;
};
export const PlusWidgetDailyPulse: FunctionComponent<Props> = ({
  className,
  pageType,
  reference,
}) => {
  const content = useFragment(
    graphql`
      fragment plusWidgetDailyPulseQuery on Query
      @argumentDefinitions(dailyPulseTypeUuId: { type: "String!" }) {
        dailyPulseQuery: articles(
          filter: { typeIds: [$dailyPulseTypeUuId] }
          first: 1
          orderBy: { field: PUBLISHED_DATE, direction: DESC }
        ) {
          edges {
            node {
              entityId
              urlAlias
              ...entityLink
              ...contentHeadlineContent
              ...hooksArticleDateUtilsContent
              ...hooksPlusArticleLinkHandlerArticle
            }
          }
        }
      }
    `,
    reference,
  );

  const topArticle = first(content.dailyPulseQuery.edges)?.node;
  const { lastUpdatedDate } = useArticleDateUtils(topArticle, "h:mma, d MMM yyyy");

  const handleButtonClickEvent = () => {
    sendGA4Tracking({
      action: "click",
      category: "recirculation",
      customized_parameters: {
        article_id: topArticle?.entityId ?? "",
        block_section: "daily_pulse",
        destination_url: topArticle?.urlAlias ?? "",
        page_type: pageType,
        widget_name: "content_discovery",
      },
      subcategory: "widget",
    });
  };

  if (!topArticle) return null;

  return (
    <Container className={className}>
      <StyledContentHeadline preferSocialHeadline reference={topArticle} />
      <LastUpdatedDate>
        Last updated: <span>{lastUpdatedDate}</span>
      </LastUpdatedDate>
      <ReadFullDailyPulseLink onClick={handleButtonClickEvent} reference={topArticle}>
        READ THE DAILY PULSE
      </ReadFullDailyPulseLink>
    </Container>
  );
};

PlusWidgetDailyPulse.displayName = "PlusWidgetDailyPulse";
