import { type FunctionComponent } from "react";
import { Autoplay } from "swiper/modules";
import { SwiperSlide } from "swiper/react";

import { quotes } from "./data";
import IconQuote from "./icon-quote.svg";
import {
  Author,
  AuthorImage,
  AuthorName,
  Container,
  Quote,
  QuoteContainer,
  StyledSwiper,
  Wrapper,
} from "./styles";

type Props = {
  className?: string;
};
export const PlusWidgetQuotes: FunctionComponent<Props> = ({ className }) => (
  <Container className={className}>
    <Wrapper>
      <StyledSwiper
        autoplay={{
          delay: 8000,
          disableOnInteraction: false,
        }}
        loop
        modules={[Autoplay]}
        navigation={false}
        pagination={false}
      >
        {quotes.map((item, index) => (
          <SwiperSlide key={index}>
            <QuoteContainer>
              <IconQuote />
              <Quote>{item.quote}</Quote>
              <Author>
                <AuthorImage alt="author" src={item.image} />
                <AuthorName>
                  {item.name}, <br />
                  {item.companyName}
                </AuthorName>
              </Author>
            </QuoteContainer>
          </SwiperSlide>
        ))}
      </StyledSwiper>
    </Wrapper>
  </Container>
);

PlusWidgetQuotes.displayName = "PlusWidgetWithoutAccess";
