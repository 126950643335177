/**
 * @generated SignedSource<<3b92c257f89cf11458e1194362dabc5d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type subsectionMenuSection$data = {
  readonly entityId: string;
  readonly name: string;
  readonly urlAlias: string;
  readonly " $fragmentSpreads": FragmentRefs<"followButtonBase">;
  readonly " $fragmentType": "subsectionMenuSection";
};
export type subsectionMenuSection$key = {
  readonly " $data"?: subsectionMenuSection$data;
  readonly " $fragmentSpreads": FragmentRefs<"subsectionMenuSection">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "subsectionMenuSection",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "followButtonBase"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entityId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "urlAlias",
      "storageKey": null
    }
  ],
  "type": "Section",
  "abstractKey": null
};

(node as any).hash = "7c8b34f764575dbef449b86a7ca0bfda";

export default node;
