import { type FunctionComponent } from "react";

import {
  DesktopContainer,
  MobileContainer,
  TabletContainer,
} from "scmp-app/components/home/placeholder";

import DesktopMostPopularPlaceholder from "./desktop-most-popular-placeholder.svg";
import MobileMostPopularPlaceholder from "./mobile-most-popular-placeholder.svg";
import TabletMostPopularPlaceholder from "./tablet-most-popular-placeholder.svg";

type Props = {
  className?: string;
};

export const HomepageMostPopularPlaceholder: FunctionComponent<Props> = () => (
  <>
    <MobileContainer>
      <MobileMostPopularPlaceholder />
    </MobileContainer>
    <TabletContainer>
      <TabletMostPopularPlaceholder />
    </TabletContainer>
    <DesktopContainer>
      <DesktopMostPopularPlaceholder />
    </DesktopContainer>
  </>
);

HomepageMostPopularPlaceholder.displayName = "HomepageMostPopularPlaceholder";
