import { theme, useResponsive, useTrackImpressionEventByIntersection } from "@product/scmp-sdk";
import { useAtomValue } from "jotai";
import { forwardRef, useCallback } from "react";

import { BaseLinkContextProvider } from "scmp-app/components/common/base-link/context";
import { userFollowAtom } from "scmp-app/components/follow-button/atoms";
import { MyNewsLink } from "scmp-app/components/home/my-news-daily-five/my-news-link";
import { RecommendArticles } from "scmp-app/components/home/my-news-daily-five/recommend-articles";
import { SetupCallToAction } from "scmp-app/components/home/my-news-daily-five/setup-call-to-action";
import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import type { RecirculationWidgetImpressionEvent } from "scmp-app/components/tracking/google-analytics-4/types";
import { tracking } from "scmp-app/data";
import { Mynews } from "scmp-app/data/tracking/module";
import { useMyNews } from "scmp-app/lib/hooks";

import MyNewsLogo from "./myNewsLogo.svg";
import { useMyNewDailyFiveArticleIds } from "./recommend-articles/hooks";
import {
  Container,
  ContentContainer,
  DownloadContainer,
  LinksContainer,
  LogoBaseLink,
  LogoContainer,
  StyledBaseLink,
  SubTitle,
  Title,
  TitleContainer,
} from "./styles";

export const MyNewsDailyFive = forwardRef<HTMLDivElement>((_, reference) => {
  const isDesktop = useResponsive(theme.breakpoints.up("desktop"), false);

  const downloadLink = isDesktop
    ? "https://app.scmp.com/"
    : "https://scmp.onelink.me/3586748601/43f04bbb";

  const { data } = useAtomValue(userFollowAtom);
  const { initialHasEnoughFollowedItems } = useMyNews();

  const { recommendedArticleIds, recommendedId } = useMyNewDailyFiveArticleIds({
    authorIds: data?.authors,
    sectionIds: data?.sections,
    topicIds: data?.topics,
  });

  const listOfArticlesIds = recommendedArticleIds?.join(",") ?? "";

  const ga4ImpressionEvent = useCallback<() => RecirculationWidgetImpressionEvent>(
    () => ({
      action: "imp",
      category: "recirculation",
      customized_parameters: {
        list_of_articles: listOfArticlesIds,
        page_type: "homepage",
        recommendation_id: recommendedId,
        widget_name: "mynews",
      },
      subcategory: "widget",
    }),
    [listOfArticlesIds, recommendedId],
  );

  const handleMyNewsLogoClick = useCallback(() => {
    // 10.1.1
    sendGA4Tracking({
      action: "click",
      category: "mynews",
      customized_parameters: {
        action_type: "redirect",
        trigger_point: "mynews_widget",
      },
      subcategory: "mydaily",
    });
  }, []);

  const { captureTrackImpressionEventTargetElement } =
    useTrackImpressionEventByIntersection<RecirculationWidgetImpressionEvent>({
      ga4TrackingHandler: sendGA4Tracking,
      getGa4Event: ga4ImpressionEvent,
      options: { isSendGA4Tracking: true, shouldSendOnce: true },
    });

  const setReference = (element: HTMLDivElement) => {
    if (typeof reference === "function") {
      reference(element);
    } else if (reference) {
      reference.current = element;
    }
    captureTrackImpressionEventTargetElement(element);
  };

  return (
    <BaseLinkContextProvider
      customQueryParameters={{
        module: tracking.module.MyDaily5,
      }}
    >
      <Container ref={setReference}>
        <LogoContainer>
          <LogoBaseLink
            as="/mynews"
            onClick={handleMyNewsLogoClick}
            pathname="/mynews"
            query={{ module: Mynews }}
          >
            <MyNewsLogo />
          </LogoBaseLink>
        </LogoContainer>
        <TitleContainer>
          <Title>My Daily 5</Title>
          <SubTitle>Five daily stories, curated for you</SubTitle>
        </TitleContainer>
        <ContentContainer>
          {initialHasEnoughFollowedItems ? (
            <RecommendArticles recommendedArticleIds={recommendedArticleIds} />
          ) : (
            <SetupCallToAction />
          )}
        </ContentContainer>
        <LinksContainer>
          {initialHasEnoughFollowedItems && (
            <MyNewsLink onClick={() => {}}>more stories you might like</MyNewsLink>
          )}
          <DownloadContainer>
            <StyledBaseLink pathname={downloadLink}>Download SCMP app</StyledBaseLink>
            <span> for the best experience</span>
          </DownloadContainer>
        </LinksContainer>
      </Container>
    </BaseLinkContextProvider>
  );
});

MyNewsDailyFive.displayName = "MyNewsDailyFive";
