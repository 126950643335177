import first from "lodash/first";
import { forwardRef } from "react";
import { graphql, useFragment } from "react-relay";

import { withHighlightedHeadline } from "scmp-app/components/content/content-item-render/highlight/hoc";
import { useContentItemProviders } from "scmp-app/components/content/content-item-render/hooks";
import type { ContentItemRenderVariantProps } from "scmp-app/components/content/content-item-render/types";
import type { homeAdvertiserContentItemContent$key } from "scmp-app/queries/__generated__/homeAdvertiserContentItemContent.graphql";

import {
  Container,
  Content,
  CoverImage,
  Headline,
  SponsorContainer,
  SponsorName,
  SponsorType,
  StyledEntityLink,
} from "./styles";

export type Props = {
  reference: homeAdvertiserContentItemContent$key;
} & ContentItemRenderVariantProps;

const Component = forwardRef<HTMLDivElement, Props>(
  ({ className, onClick, reference: reference_ }, reference) => {
    const content = useFragment(
      graphql`
        fragment homeAdvertiserContentItemContent on Content {
          ...entityLink
          entityId
          urlAlias
          topics {
            sponsor {
              name
              type
            }
          }
          ...hooksContentItemProvidersContent
            @arguments(withCoverImage: true, withCoverImageSize540x360: true, withHeadline: true)
        }
      `,
      reference_,
    );

    const providers = useContentItemProviders(content);

    const sponsor = first(content?.topics)?.sponsor;
    const sponsorName = sponsor?.name;
    const sponsorType = sponsor?.type;

    return (
      <Container className={className} ref={reference}>
        <StyledEntityLink
          onClick={() => onClick?.(content.entityId, content.urlAlias)}
          reference={content}
        >
          <CoverImage>
            {providers.coverImage({
              responsiveVariants: {
                desktopUp: "size540x360",
                mobileUp: "size540x360",
                tabletUp: "size540x360",
              },
              withCaption: false,
            })}
          </CoverImage>
          <Content>
            <Headline>
              {providers.headline({
                hideTooltip: true,
                preferSocialHeadline: true,
                skipHeadlineSponsorStyle: true,
              })}
            </Headline>
          </Content>
        </StyledEntityLink>
        <SponsorContainer>
          <SponsorType>{sponsorType}</SponsorType>
          <SponsorName>{sponsorName}</SponsorName>
        </SponsorContainer>
      </Container>
    );
  },
);

Component.displayName = "ContentItemHomeAdvertiser";

export const ContentItemHomeAdvertiser = withHighlightedHeadline(Component);
