import { type PageType, useTrackImpressionEventByIntersection } from "@product/scmp-sdk";
import { useCallback } from "react";
import type { FunctionComponent, ReactNode } from "react";
import { graphql, useFragment } from "react-relay";

import { BaseLinkContextProvider } from "scmp-app/components/common/base-link/context";
import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import type { RecirculationWidgetImpressionEvent } from "scmp-app/components/tracking/google-analytics-4/types";
import { tracking } from "scmp-app/data";
import { useCurrentPageType } from "scmp-app/lib/router/hooks";
import { getAbsoluteUrl } from "scmp-app/lib/utils";
import type { mostPopularQueueQuery$key } from "scmp-app/queries/__generated__/mostPopularQueueQuery.graphql";

import {
  Container,
  DisplayNumber,
  MostPopularItem,
  MostPopularItems,
  StyledContentItemHomeSecondary,
  Title,
  TrendingTopicContainer,
} from "./styles";

type Props = {
  className?: string;
  reference: mostPopularQueueQuery$key;
  trendingTopicSlot?: ReactNode;
};

export const MostPopular: FunctionComponent<Props> = ({
  className,
  reference: reference_,
  trendingTopicSlot,
}) => {
  const data = useFragment(
    graphql`
      fragment mostPopularQueueQuery on Query
      @argumentDefinitions(mostPopularQueueName: { type: "String!" }) {
        mostPopularQueueQuery: queue(filter: { name: $mostPopularQueueName }) {
          items(first: 5) {
            edges {
              node {
                ... on Content {
                  entityId
                  ...homeSecondaryContentItemContent
                }
              }
            }
          }
        }
      }
    `,
    reference_,
  );

  const mostPopularItems = data?.mostPopularQueueQuery?.items?.edges;
  const listOfArticles = (mostPopularItems?.map(item => item.node.entityId) ?? []).join(",");

  /** TODO: find a way to restrict page type in a shared component */
  const currentPageType = useCurrentPageType() as
    | PageType.Homepage
    | PageType.Section
    | PageType.SubSection;

  const impressionGA4Event = useCallback<() => RecirculationWidgetImpressionEvent>(
    () => ({
      action: "imp",
      category: "recirculation",
      customized_parameters: {
        list_of_articles: listOfArticles,
        page_type: currentPageType,
        widget_name: "most_popular",
      },
      subcategory: "widget",
    }),
    [currentPageType, listOfArticles],
  );

  const { captureTrackImpressionEventTargetElement } =
    useTrackImpressionEventByIntersection<RecirculationWidgetImpressionEvent>({
      ga4TrackingHandler: sendGA4Tracking,
      getGa4Event: impressionGA4Event,
      options: { isSendGA4Tracking: true, shouldSendOnce: true },
    });

  if (mostPopularItems?.length === 0) return null;

  return (
    <BaseLinkContextProvider
      customQueryParameters={{
        module: tracking.module.MostPopular,
      }}
    >
      <Container className={className} ref={captureTrackImpressionEventTargetElement}>
        <Title>MOST POPULAR</Title>
        <MostPopularItems>
          {mostPopularItems?.map(({ node }, index) => (
            <MostPopularItem key={node.entityId}>
              <DisplayNumber>{index + 1}</DisplayNumber>
              <StyledContentItemHomeSecondary
                onClick={(entityId, urlAlias) => {
                  sendGA4Tracking({
                    action: "click",
                    category: "recirculation",
                    customized_parameters: {
                      article_id: entityId,
                      destination_url: getAbsoluteUrl(urlAlias),
                      page_type: currentPageType,
                      widget_name: "most_popular",
                    },
                    subcategory: "widget",
                  });
                }}
                reference={node}
              />
            </MostPopularItem>
          ))}
        </MostPopularItems>

        {trendingTopicSlot && <TrendingTopicContainer>{trendingTopicSlot}</TrendingTopicContainer>}
      </Container>
    </BaseLinkContextProvider>
  );
};

MostPopular.displayName = "MostPopular";
