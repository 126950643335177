import { forwardRef } from "react";
import { graphql, useFragment } from "react-relay";

import { withHighlightedHeadline } from "scmp-app/components/content/content-item-render/highlight/hoc";
import { useContentItemProviders } from "scmp-app/components/content/content-item-render/hooks";
import type { ContentItemRenderVariantProps } from "scmp-app/components/content/content-item-render/types";
import type { homeLatestContentItemContent$key } from "scmp-app/queries/__generated__/homeLatestContentItemContent.graphql";

import {
  ActionBar,
  ActionBarContainer,
  Container,
  ContentContainer,
  CoverImage,
  Headline,
  Section,
  StyledEntityLink,
} from "./styles";

export type Props = {
  reference: homeLatestContentItemContent$key;
} & ContentItemRenderVariantProps;

const Component = forwardRef<HTMLDivElement, Props>(
  ({ className, onClick, reference: reference_ }, reference) => {
    const content = useFragment(
      graphql`
        fragment homeLatestContentItemContent on Content {
          entityId
          urlAlias
          ...entityLink
          ...hooksContentItemProvidersContent
            @arguments(
              withActionBar: true
              withSections: true
              withHeadline: true
              withCoverImage: true
              withCoverImageSize540x360: true
              withCoverImageSize1200x800: true
              withVideoDurationOverlay: true
            )
          ... on Video {
            duration
          }
        }
      `,
      reference_,
    );
    const providers = useContentItemProviders(content);

    return (
      <Container className={className} ref={reference}>
        <ContentContainer>
          <ActionBarContainer>
            <ActionBar>
              {providers.actionBar({
                displayDateVariant: "publishedDate",
                isBookmarkVisible: false,
                isCommentsVisible: false,
                isIconVisible: false,
              })}
            </ActionBar>
            <Section>{providers.sections()}</Section>
          </ActionBarContainer>
          <StyledEntityLink
            onClick={() => onClick?.(content.entityId, content.urlAlias)}
            reference={content}
          >
            <Headline>
              {providers.headline({
                hideTooltip: true,
                preferSocialHeadline: true,
              })}
            </Headline>
          </StyledEntityLink>
        </ContentContainer>

        {content.duration && (
          <StyledEntityLink
            onClick={() => onClick?.(content.entityId, content.urlAlias)}
            reference={content}
          >
            <CoverImage>
              {providers.coverImage({
                responsiveVariants: {
                  desktopUp: "size540x360",
                  mobileUp: "size1200x800",
                  tabletUp: "size540x360",
                },
              })}
              {providers.video?.durationOverlay({
                variant: "homepage",
              })}
            </CoverImage>
          </StyledEntityLink>
        )}
      </Container>
    );
  },
);

Component.displayName = "ContentItemHomeLatest";

export const ContentItemHomeLatest = withHighlightedHeadline(Component);
