import { type FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import { getAbsoluteUrl } from "scmp-app/lib/utils";
import type { aroundItemQueueItemsEdge$key } from "scmp-app/queries/__generated__/aroundItemQueueItemsEdge.graphql";

import {
  Container,
  Content,
  StyledContentItemHomeAroundMorningStudioPrimary,
  StyledContentItemHomeAroundMorningStudioSecondary,
  StyledContentItemHomeAroundPrimary,
  StyledContentItemHomeAroundSecondary,
  StyledScmpLabLogo,
  Title,
  TitleContainer,
} from "./styles";

const handleClick = (entityId = "", urlAlias = "") => {
  sendGA4Tracking({
    action: "click",
    category: "recirculation",
    customized_parameters: {
      article_id: entityId,
      destination_url: getAbsoluteUrl(urlAlias),
      page_type: "homepage",
      widget_name: "around_scmp",
    },
    subcategory: "widget",
  });
};

type Props = {
  className?: string;
  reference: aroundItemQueueItemsEdge$key;
  title: string;
  urlAlias: string;
  withSCMPLabLogo?: boolean;
};

export const HomeAroundItem: FunctionComponent<Props> = ({
  className,
  reference: reference_,
  title,
  urlAlias,
  withSCMPLabLogo,
}) => {
  const data = useFragment(
    graphql`
      fragment aroundItemQueueItemsEdge on QueueItemsEdge @relay(plural: true) {
        node {
          ... on Content {
            entityId
            ...homeAroundPrimaryContentItemContent
            ...homeAroundSecondaryContentItemContent
            ...homeAroundMorningStudioPrimaryContentItemContent
            ...homeAroundMorningStudioSecondaryContentItemContent
          }
        }
      }
    `,
    reference_,
  );

  const renderAroundItems = () => {
    const isMorningStudio = title === "MORNING STUDIO";
    return data?.map((item, key) => {
      if (isMorningStudio) {
        return key === 0 ? (
          <StyledContentItemHomeAroundMorningStudioPrimary
            key={item?.node.entityId}
            onClick={handleClick}
            reference={item?.node}
          />
        ) : (
          <StyledContentItemHomeAroundMorningStudioSecondary
            key={item?.node.entityId}
            onClick={handleClick}
            reference={item?.node}
          />
        );
      } else {
        return key === 0 ? (
          <StyledContentItemHomeAroundPrimary
            key={item?.node.entityId}
            onClick={handleClick}
            reference={item?.node}
          />
        ) : (
          <StyledContentItemHomeAroundSecondary
            key={item?.node.entityId}
            onClick={handleClick}
            reference={item?.node}
          />
        );
      }
    });
  };

  return (
    <Container className={className}>
      {withSCMPLabLogo ? (
        <TitleContainer>
          <Title pathname={urlAlias}>{title}</Title>
          <StyledScmpLabLogo />
        </TitleContainer>
      ) : (
        <Title pathname={urlAlias}>{title}</Title>
      )}
      <Content>{renderAroundItems()}</Content>
    </Container>
  );
};

HomeAroundItem.displayName = "HomeAroundItem";
