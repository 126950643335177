import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { ContentItemPlusHighlight } from "scmp-app/components/content/content-item-render/variants/plus-widget-highlight";
import IconArrow from "scmp-app/components/plus/plus-widget/plus-widget-news-agenda/icon-arrow.svg";
import type { plusWidgetHighlightsQuery$key } from "scmp-app/queries/__generated__/plusWidgetHighlightsQuery.graphql";

import { Container, ContentContainer, MoreOnLink } from "./styles";

type Props = {
  className?: string;
  pageType: "homepage" | "section" | "topic";
  reference: plusWidgetHighlightsQuery$key;
};

export const PlusWidgetHighlights: FunctionComponent<Props> = ({
  className,
  pageType,
  reference,
}) => {
  const content = useFragment(
    graphql`
      fragment plusWidgetHighlightsQuery on Query
      @argumentDefinitions(queueName: { type: "String!" }) {
        highlightQueue: queue(filter: { name: $queueName }) {
          items(first: 2) {
            edges {
              node {
                ... on Content {
                  entityId
                  ...plusWidgetHighlight
                }
              }
            }
          }
        }
      }
    `,
    reference,
  );

  return (
    <Container className={className}>
      <ContentContainer>
        {content.highlightQueue?.items?.edges.map(item => (
          <ContentItemPlusHighlight key={item.node.entityId} reference={item.node} />
        ))}
      </ContentContainer>
      <MoreOnLink pathname="/plus" query={{ module: "content_discovery", pgtype: pageType }}>
        <span>More on SCMP PLUS</span> <IconArrow />
      </MoreOnLink>
    </Container>
  );
};

PlusWidgetHighlights.displayName = "PlusWidgetHighlights";
