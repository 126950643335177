import { FollowType, notEmpty } from "@product/scmp-sdk";
import { useMemo } from "react";
import { graphql, useFragment } from "react-relay";

import { SectionTermLogo } from "scmp-app/components/home/term-logo/section";
import { TopicTermLogo } from "scmp-app/components/home/term-logo/topic";
import type { hooksHomeStoryPackageTopicQueue$key } from "scmp-app/queries/__generated__/hooksHomeStoryPackageTopicQueue.graphql";

import {
  StyledTopicFollowButton,
  TopicName,
  TopicNameBaseLink,
  TopicNameEntityLink,
} from "./styles";

type Settings = {
  custom_text?: string;
};

export const useHomeStoryPackageTopic = (reference?: hooksHomeStoryPackageTopicQueue$key) => {
  const data = useFragment(
    graphql`
      fragment hooksHomeStoryPackageTopicQueue on Queue {
        refTerms {
          ... on Newsletter {
            name
            latestArticleLink
            __typename
          }
          ... on Section {
            name
            ...entityLink
            ...followButtonBase
            ...sectionTermLogoSection
            __typename
          }
          ... on Topic {
            name
            ...entityLink
            ...followButtonBase
            ...topicTermLogoTopic
            __typename
          }
        }
        settings
      }
    `,
    reference,
  );

  const term = data?.refTerms?.[0];
  const customText = (data?.settings as Settings)?.custom_text;
  const typename = term?.__typename;

  const termLogo = useMemo(() => {
    if (!notEmpty(term)) return null;
    switch (typename) {
      case "Section":
        return <SectionTermLogo reference={term} />;
      case "Topic":
        return <TopicTermLogo reference={term} />;
      default:
        return null;
    }
  }, [typename, term]);

  if (typename && typename !== "%other") {
    const displayText = customText === "" ? term.name : customText;
    const topicName = <TopicName>{displayText}</TopicName>;

    switch (typename) {
      case "Newsletter":
        return {
          component: term?.latestArticleLink ? (
            <TopicNameBaseLink pathname={term.latestArticleLink}>{topicName}</TopicNameBaseLink>
          ) : (
            topicName
          ),
          name: displayText,
        };
      case "Section":
      case "Topic":
        if (notEmpty(term))
          return {
            component: (
              <>
                {termLogo}
                <TopicNameEntityLink reference={term}>{topicName}</TopicNameEntityLink>
                <StyledTopicFollowButton
                  reference={term}
                  source="Homepage_feature_package"
                  type={typename === "Section" ? FollowType.Section : FollowType.Topic}
                />
              </>
            ),
            name: displayText,
          };

        return { component: null, name: displayText };
    }
  } else {
    const displayText = customText === "" ? "STORY PACKAGE" : customText;
    return { component: <TopicName>{displayText}</TopicName>, name: displayText };
  }
};
