import { useTrackImpressionEventByIntersection } from "@product/scmp-sdk";
import type { FunctionComponent } from "react";
import { useCallback } from "react";
import { graphql, useFragment } from "react-relay";

import LogoPlus from "scmp-app/components/plus/plus-widget/logo-plus.svg";
import { PlusWidgetDailyPulse } from "scmp-app/components/plus/plus-widget/plus-widget-daily-pulse";
import { PlusWidgetHighlights } from "scmp-app/components/plus/plus-widget/plus-widget-highlights";
import { PlusWidgetNewsAgenda } from "scmp-app/components/plus/plus-widget/plus-widget-news-agenda";
import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import type { RecirculationWidgetImpressionEvent } from "scmp-app/components/tracking/google-analytics-4/types";
import type { plusWidgetHasAccessQuery$key } from "scmp-app/queries/__generated__/plusWidgetHasAccessQuery.graphql";

import {
  Container,
  DailyPulse,
  HighlightsFromScmpPlus,
  LogoPlusContainer,
  NewsAgenda,
  Title,
} from "./styles";

type Props = {
  className?: string;
  pageType: "homepage" | "section" | "topic";
  reference: plusWidgetHasAccessQuery$key;
};
export const PlusWidgetHasAccess: FunctionComponent<Props> = ({
  className,
  pageType,
  reference,
}) => {
  const ga4ImpressionEvent = useCallback<() => RecirculationWidgetImpressionEvent>(
    () => ({
      action: "imp",
      category: "recirculation",
      customized_parameters: {
        page_type: pageType,
        widget_name: "content_discovery",
      },
      subcategory: "widget",
    }),
    [pageType],
  );

  const { captureTrackImpressionEventTargetElement } =
    useTrackImpressionEventByIntersection<RecirculationWidgetImpressionEvent>({
      ga4TrackingHandler: sendGA4Tracking,
      getGa4Event: ga4ImpressionEvent,
      options: {
        isSendGA4Tracking: true,
        shouldSendOnce: true,
      },
    });

  const data = useFragment(
    graphql`
      fragment plusWidgetHasAccessQuery on Query
      @argumentDefinitions(
        applicationId: { type: "String!" }
        dailyPulseTypeUuId: { type: "String!" }
        highlightQueueName: { type: "String!" }
        plusNewsAgendaStartDate: { type: "Timestamp!" }
        plusNewsAgendaEndDate: { type: "Timestamp!" }
        plusNewsAgendaTypes: { type: "[String]!" }
      ) {
        ...plusWidgetDailyPulseQuery @arguments(dailyPulseTypeUuId: $dailyPulseTypeUuId)
        ...plusWidgetHighlightsQuery @arguments(queueName: $highlightQueueName)
        ...plusWidgetNewsAgendaQuery
          @arguments(
            applicationId: $applicationId
            plusNewsAgendaEndDate: $plusNewsAgendaEndDate
            plusNewsAgendaStartDate: $plusNewsAgendaStartDate
            plusNewsAgendaTypes: $plusNewsAgendaTypes
          )
      }
    `,
    reference,
  );

  return (
    <Container className={className} ref={captureTrackImpressionEventTargetElement}>
      <LogoPlusContainer>
        <LogoPlus />
      </LogoPlusContainer>
      <DailyPulse>
        <Title>DAILY PULSE</Title>
        <PlusWidgetDailyPulse pageType={pageType} reference={data} />
      </DailyPulse>
      <NewsAgenda>
        <Title>NEWS AGENDA</Title>
        <PlusWidgetNewsAgenda pageType={pageType} reference={data} />
      </NewsAgenda>
      <HighlightsFromScmpPlus>
        <Title>Highlights from SCMP Plus</Title>
        <PlusWidgetHighlights pageType={pageType} reference={data} />
      </HighlightsFromScmpPlus>
    </Container>
  );
};

PlusWidgetHasAccess.displayName = "PlusWidgetHasAccess";
