/**
 * @generated SignedSource<<9cb74299a23892ed1d1d515c560601b9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type superSplashLiveLiveWidget$data = {
  readonly label: string | null | undefined;
  readonly relatedArticles: ReadonlyArray<{
    readonly entityUuid: string;
    readonly urlAlias: string;
    readonly " $fragmentSpreads": FragmentRefs<"homeSuperSplashLivePrimaryContentItemContent" | "homeSuperSplashLiveSecondaryContentItemContent">;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "superSplashLiveLiveWidget";
};
export type superSplashLiveLiveWidget$key = {
  readonly " $data"?: superSplashLiveLiveWidget$data;
  readonly " $fragmentSpreads": FragmentRefs<"superSplashLiveLiveWidget">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "superSplashLiveLiveWidget",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Article",
      "kind": "LinkedField",
      "name": "relatedArticles",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "entityUuid",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "urlAlias",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "homeSuperSplashLivePrimaryContentItemContent"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "homeSuperSplashLiveSecondaryContentItemContent"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "LiveWidget",
  "abstractKey": null
};

(node as any).hash = "5dc901463cf999c9978361f6389183a9";

export default node;
