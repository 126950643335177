import { type FunctionComponent, type ReactNode, useMemo } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";

import type { AIEngineResponse } from "scmp-app/components/home/ai-article-list/hooks";
import { useFetchAiArticles } from "scmp-app/components/home/ai-article-list/hooks";
import { AiEngineDataType } from "scmp-app/lib/ai-engine/enums";
import { Edition, useEditionValue } from "scmp-app/lib/edition";
import { useGetEntityIdsByQueueItems } from "scmp-app/lib/hooks";
import type {
  clientQueryLoaderHomeQuery,
  clientQueryLoaderHomeQuery$data,
} from "scmp-app/queries/__generated__/clientQueryLoaderHomeQuery.graphql";

type Payload = {
  aiWidgets: Record<
    AiEngineDataType.HomeRecommendedForYou | AiEngineDataType.HomeYouMayHaveMissed,
    AIEngineResponse
  >;
  data: clientQueryLoaderHomeQuery$data;
  existingEntityIds: string[];
};

export type Props = {
  children?: (payload: Payload) => ReactNode;
  ssrContentEntityIds: string[];
};

export const HomeClientQueryLoader: FunctionComponent<Props> = ({
  children,
  ssrContentEntityIds,
}) => {
  // No need to SSR for this one so it's ok to use cookie value here
  const edition = useEditionValue();

  const data = useLazyLoadQuery<clientQueryLoaderHomeQuery>(
    graphql`
      query clientQueryLoaderHomeQuery(
        $advertisersQueueName: String!
        $excludeSsrContentEntityIds: [String]!
        $isAsiaEdition: Boolean!
        $mostPopularQueueName: String!
        $multimediaQueueName: String!
        $trendingTopicsQueueName: String!
      ) {
        advertisers: queue(filter: { name: $advertisersQueueName }) {
          items(first: 20) {
            ...advertisersQueueItemConnection
          }
        }
        china: queue(filter: { name: "section_top_4" }) {
          items(first: 7, exclude: { entityIds: $excludeSsrContentEntityIds }) {
            edges {
              ...contentUtilsGetEntityIdsByQueueItemQueueItemsEdge
            }
          }
        }
        hongKong: queue(filter: { name: "section_top_2" }) @skip(if: $isAsiaEdition) {
          items(first: 7, exclude: { entityIds: $excludeSsrContentEntityIds }) {
            edges {
              ...contentUtilsGetEntityIdsByQueueItemQueueItemsEdge
            }
          }
        }
        lifestyle: queue(filter: { name: "section_top_94" }) {
          items(first: 5, exclude: { entityIds: $excludeSsrContentEntityIds }) {
            edges {
              ...contentUtilsGetEntityIdsByQueueItemQueueItemsEdge
            }
          }
        }
        peopleCulture: queue(filter: { name: "section_top_318202" }) {
          items(first: 5, exclude: { entityIds: $excludeSsrContentEntityIds }) {
            edges {
              ...contentUtilsGetEntityIdsByQueueItemQueueItemsEdge
            }
          }
        }
        style: queue(filter: { name: "section_top_72" }) {
          items(first: 5, exclude: { entityIds: $excludeSsrContentEntityIds }) {
            edges {
              ...contentUtilsGetEntityIdsByQueueItemQueueItemsEdge
            }
          }
        }
        twia: queue(filter: { name: "section_top_323045" }) @include(if: $isAsiaEdition) {
          items(first: 7, exclude: { entityIds: $excludeSsrContentEntityIds }) {
            edges {
              ...contentUtilsGetEntityIdsByQueueItemQueueItemsEdge
            }
          }
        }
        ...mostPopularQueueQuery @arguments(mostPopularQueueName: $mostPopularQueueName)
        ...multimediaQuery @arguments(multimediaQueueName: $multimediaQueueName)
        ...sectionTopQuery
          @arguments(
            isAsiaEdition: $isAsiaEdition
            excludeSsrContentEntityIds: $excludeSsrContentEntityIds
          )
        ...trendingTopicTopic @arguments(trendingTopicsQueueName: $trendingTopicsQueueName)
      }
    `,
    {
      advertisersQueueName: "homepage_brand_post_int",
      excludeSsrContentEntityIds: ssrContentEntityIds,
      isAsiaEdition: edition === Edition.Asia,
      mostPopularQueueName: "scmp_trending_section_scmp_trending_section_homepage_last_24_hours",
      multimediaQueueName: `visual_stories_top_${edition}`,
      trendingTopicsQueueName: `related_topic_homepage_${edition}_`,
    },
    { fetchPolicy: "store-or-network" },
  );

  const sectionEdges = useMemo(
    () => [
      ...(data?.hongKong?.items?.edges ?? []),
      ...(data?.china?.items?.edges ?? []),
      ...(data?.lifestyle?.items?.edges ?? []),
      ...(data?.peopleCulture?.items?.edges ?? []),
      ...(data?.style?.items?.edges ?? []),
      ...(data?.twia?.items?.edges ?? []),
    ],
    [
      data?.china?.items?.edges,
      data?.hongKong?.items?.edges,
      data?.lifestyle?.items?.edges,
      data?.peopleCulture?.items?.edges,
      data?.style?.items?.edges,
      data?.twia?.items?.edges,
    ],
  );
  const existingEntityIds = useGetEntityIdsByQueueItems(sectionEdges, ssrContentEntityIds);

  const recommendedForYouAIEngineResponse = useFetchAiArticles(
    AiEngineDataType.HomeRecommendedForYou,
    existingEntityIds,
  );

  const youMayHaveMissedAIEngineResponse = useFetchAiArticles(
    AiEngineDataType.HomeYouMayHaveMissed,
    [...existingEntityIds, ...recommendedForYouAIEngineResponse.entityIds],
  );

  return children?.({
    aiWidgets: {
      [AiEngineDataType.HomeRecommendedForYou]: recommendedForYouAIEngineResponse,
      [AiEngineDataType.HomeYouMayHaveMissed]: youMayHaveMissedAIEngineResponse,
    },
    data,
    existingEntityIds,
  });
};

HomeClientQueryLoader.displayName = "HomeClientQueryLoader";
