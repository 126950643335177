import { type FunctionComponent } from "react";

import {
  DesktopContainer,
  MobileContainer,
  TabletContainer,
} from "scmp-app/components/home/placeholder";

import DesktopAiPlaceholder from "./desktop-ai-placeholder.svg";
import MobileAiPlaceholder from "./mobile-ai-placeholder.svg";
import TabletAiPlaceholder from "./tablet-ai-placeholder.svg";

type Props = {
  className?: string;
};

export const HomepageAiPlaceholder: FunctionComponent<Props> = () => (
  <>
    <MobileContainer>
      <MobileAiPlaceholder />
    </MobileContainer>
    <TabletContainer>
      <TabletAiPlaceholder />
    </TabletContainer>
    <DesktopContainer>
      <DesktopAiPlaceholder />
    </DesktopContainer>
  </>
);

HomepageAiPlaceholder.displayName = "HomepageAiPlaceholder";
