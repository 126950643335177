import styled from "@emotion/styled";
import { fontRobotoCondensed } from "@product/scmp-sdk";

import { BaseLink } from "scmp-app/components/common/base-link";
import { ContentItemHomeAroundMorningStudioPrimary } from "scmp-app/components/content/content-item-render/variants/home-around-morning-studio-primary";
import { ContentItemHomeAroundMorningStudioSecondary } from "scmp-app/components/content/content-item-render/variants/home-around-morning-studio-secondary";
import { ContentItemHomeAroundPrimary } from "scmp-app/components/content/content-item-render/variants/home-around-primary";
import { ContentItemHomeAroundSecondary } from "scmp-app/components/content/content-item-render/variants/home-around-secondary";

import ScmpLabLogo from "./scmp-lab-logo.svg";

export const StyledContentItemHomeAroundPrimary = styled(ContentItemHomeAroundPrimary)``;
export const StyledContentItemHomeAroundSecondary = styled(ContentItemHomeAroundSecondary)``;
export const StyledContentItemHomeAroundMorningStudioPrimary = styled(
  ContentItemHomeAroundMorningStudioPrimary,
)``;
export const StyledContentItemHomeAroundMorningStudioSecondary = styled(
  ContentItemHomeAroundMorningStudioSecondary,
)``;

export const Container = styled.div``;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled(BaseLink)`
  color: #4585ff;
  font-weight: 700;
  font-size: 13px;
  font-family: ${fontRobotoCondensed};
  line-height: 15px;
`;

export const StyledScmpLabLogo = styled(ScmpLabLogo)``;

export const Content = styled.div`
  margin-block-start: 12px;

  overflow: hidden;

  & > *:not(:last-child) {
    margin-block-end: 12px;
    padding-block-end: 12px;

    border-block-end: 1px solid rgba(0, 0, 0, 0.1);
  }
`;
