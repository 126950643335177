import { forwardRef } from "react";
import { graphql, useFragment } from "react-relay";

import { withHighlightedHeadline } from "scmp-app/components/content/content-item-render/highlight/hoc";
import { useContentItemProviders } from "scmp-app/components/content/content-item-render/hooks";
import type { ContentItemRenderVariantProps } from "scmp-app/components/content/content-item-render/types";
import type { homeSuperSplashLiveSecondaryContentItemContent$key } from "scmp-app/queries/__generated__/homeSuperSplashLiveSecondaryContentItemContent.graphql";

import {
  ActionBar,
  Container,
  Content,
  Headline,
  HeadlineContainer,
  LiveTagContainer,
  StyledEntityLink,
} from "./styles";

export type Props = {
  reference: homeSuperSplashLiveSecondaryContentItemContent$key;
  withComment?: boolean;
} & ContentItemRenderVariantProps;

const Component = forwardRef<HTMLDivElement, Props>(
  ({ className, onClick, reference: reference_, withComment = false }, reference) => {
    const content = useFragment(
      graphql`
        fragment homeSuperSplashLiveSecondaryContentItemContent on Content {
          entityId
          ...entityLink
          ...hooksContentItemProvidersContent
            @arguments(withHeadline: true, withLiveTag: true, withActionBar: true)
        }
      `,
      reference_,
    );
    const providers = useContentItemProviders(content);

    return (
      <Container className={className} ref={reference}>
        <Content>
          <StyledEntityLink onClick={() => onClick?.(content.entityId)} reference={content}>
            <HeadlineContainer>
              <LiveTagContainer>{providers.liveTag()}</LiveTagContainer>
              <Headline>
                {providers.headline({
                  hideTooltip: true,
                  preferSocialHeadline: true,
                  skipHighlight: true,
                })}
              </Headline>
            </HeadlineContainer>
          </StyledEntityLink>
          <ActionBar>
            {providers.actionBar({
              commentVariant: "reverse",
              isBookmarkVisible: false,
              isCommentsVisible: withComment ?? false,
            })}
          </ActionBar>
        </Content>
      </Container>
    );
  },
);

Component.displayName = "ContentItemHomeSuperSplashLiveSecondary";

export const ContentItemHomeSuperSplashLiveSecondary = withHighlightedHeadline(Component);
