import styled from "@emotion/styled";
import { fontMerriweather } from "@product/scmp-sdk";

import { EntityLink } from "scmp-app/components/entity-link";

export const Container = styled.div``;

export const StyledEntityLink = styled(EntityLink)`
  display: flex;
  flex-direction: column;

  color: #000000;

  &:hover {
    color: #2c4692;
  }
`;

export const Headline = styled.div`
  font-weight: 400;
  font-size: 15px;
  font-family: ${fontMerriweather};
  line-height: 21px;
`;
