import styled from "@emotion/styled";

export const MobileContainer = styled.div`
  display: flex;
  row-gap: 32px;

  ${props => props.theme.breakpoints.up("tablet")} {
    display: none;
  }
`;

export const TabletContainer = styled.div`
  display: none;

  ${props => props.theme.breakpoints.up("tablet")} {
    display: flex;
    row-gap: 32px;
  }

  ${props => props.theme.breakpoints.up("desktop")} {
    display: none;
  }
`;

export const DesktopContainer = styled.div`
  display: none;

  ${props => props.theme.breakpoints.up("desktop")} {
    display: flex;
    row-gap: 48px;
  }
`;
