import styled from "@emotion/styled";
import { fontRobotoCondensed } from "@product/scmp-sdk";

import { EntityLink } from "scmp-app/components/entity-link";

import RightArrowIcon from "./right-arrow.svg";

export const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;

  block-size: 100%;

  overflow: hidden;

  ${props => props.theme.breakpoints.up("tablet")} {
    column-gap: 16px;

    &::before {
      content: "-";

      line-height: 23px;
    }
  }
`;

export const MenuItemsContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;

  white-space: nowrap;
  column-gap: 16px;

  overflow-x: auto;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledEntityLink = styled(EntityLink)`
  color: #000000;
  font-weight: 400;
  font-size: 14px;
  font-family: ${fontRobotoCondensed};
  line-height: 140%;
`;

export const StyledRightArrowIcon = styled(RightArrowIcon)`
  inline-size: 24px;
  block-size: 25px;
  min-inline-size: 24px;

  ${props => props.theme.breakpoints.up("tablet")} {
    display: none;
  }
`;
