/**
 * @generated SignedSource<<f6d9b9e081d909837e10b5633f946bb9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type homeStoryPackageTailContentItemContent$data = {
  readonly entityId: string;
  readonly urlAlias: string;
  readonly " $fragmentSpreads": FragmentRefs<"entityLink" | "hooksContentItemProvidersContent">;
  readonly " $fragmentType": "homeStoryPackageTailContentItemContent";
};
export type homeStoryPackageTailContentItemContent$key = {
  readonly " $data"?: homeStoryPackageTailContentItemContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"homeStoryPackageTailContentItemContent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "homeStoryPackageTailContentItemContent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entityId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "urlAlias",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "entityLink"
    },
    {
      "args": [
        {
          "kind": "Literal",
          "name": "withActionBar",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withHeadline",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withLiveTag",
          "value": true
        }
      ],
      "kind": "FragmentSpread",
      "name": "hooksContentItemProvidersContent"
    }
  ],
  "type": "Content",
  "abstractKey": "__isContent"
};

(node as any).hash = "242bfab833edabb1f41484f0fbc3fd8f";

export default node;
