import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { fontRoboto, fontRobotoCondensed } from "@product/scmp-sdk";

import { Figure } from "scmp-app/components/content/content-cover-image/styles";
import { EntityLink } from "scmp-app/components/entity-link";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  block-size: 100%;
`;

export const StyledEntityLink = styled(EntityLink)`
  display: flex;
  flex: 1;
  flex-direction: column;

  color: #000000;

  &:hover {
    color: #2c4692;
  }
`;

export const CoverImage = styled.div`
  position: relative;
  ${Figure} {
    aspect-ratio: 3/2;
  }
`;

export const Content = styled.div`
  margin-block-start: 12px;
`;

export const Headline = styled.div`
  display: -webkit-box;

  font-weight: 700;
  font-size: 18px;
  font-family: ${fontRobotoCondensed};
  font-style: normal;
  line-height: 25.2px;
  text-overflow: ellipsis;

  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  /* stylelint-disable property-no-unknown */
  line-clamp: 4;
  /* stylelint-enable property-no-unknown */

  ${props => props.theme.breakpoints.up("tablet")} {
    font-size: 16px;
    line-height: 22.4px;
  }
`;

export const SponsorContainer = styled.div`
  margin-block-start: 20px;
  padding-block: 4px;
  padding-inline: 6px;

  border: 1px solid rgba(0, 0, 0, 0.2);
`;

const SponsorTextStyle = css`
  color: #000000;
  font-size: 12px;
  font-family: ${fontRoboto};
  line-height: 14px;
`;

export const SponsorType = styled.div`
  ${SponsorTextStyle};
  opacity: 0.5;
`;

export const SponsorName = styled.div`
  ${SponsorTextStyle};
  font-weight: 700;
`;
