import { useAtomValue } from "jotai/index";
import { useMemo } from "react";

import { getAccountState } from "scmp-app/lib/account";
import { rosettaAtom } from "scmp-app/lib/rosetta";

export const useRosettaUser = () => {
  const { isLoggedIn } = getAccountState();
  const asyncRosettaState = useAtomValue(rosettaAtom);

  const isSCMPDigitalOnlySubscriber = useMemo(() => {
    if (!asyncRosettaState?.result?.v2?.user?.checkHasAccessToResource) return false;

    const hasDigitalAccess = asyncRosettaState?.result?.v2?.user?.checkHasAccessToResource({
      targetMedia: ["digital"],
      targetResourceFamily: "scmp",
    });

    const hasScmpPlusAccess = asyncRosettaState?.result?.v2?.user?.checkHasAccessToResource({
      targetMedia: ["digital"],
      targetResourceFamily: "scmpPlus",
    });

    return isLoggedIn && hasDigitalAccess && !hasScmpPlusAccess;
  }, [asyncRosettaState?.result?.v2?.user, isLoggedIn]);

  return {
    isSCMPDigitalOnlySubscriber,
  };
};
