import { type FunctionComponent } from "react";

import {
  DesktopContainer,
  MobileContainer,
  TabletContainer,
} from "scmp-app/components/home/placeholder";

import DesktopSubscriptionPlaceholder from "./desktop-subscription-placeholder.svg";
import MobileSubscriptionPlaceholder from "./mobile-subscription-placeholder.svg";
import TabletSubscriptionPlaceholder from "./tablet-subscription-placeholder.svg";

type Props = {
  className?: string;
};

export const HomepageSubscriptionPlaceholder: FunctionComponent<Props> = () => (
  <>
    <MobileContainer>
      <MobileSubscriptionPlaceholder />
    </MobileContainer>
    <TabletContainer>
      <TabletSubscriptionPlaceholder />
    </TabletContainer>
    <DesktopContainer>
      <DesktopSubscriptionPlaceholder />
    </DesktopContainer>
  </>
);

HomepageSubscriptionPlaceholder.displayName = "HomepageSubscriptionPlaceholder";
