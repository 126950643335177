export enum AiEngineDataType {
  HomeRecommendedForYou,
  HomeYouMayHaveMissed,
  MoreOnThisTopic,
  MyNewsFresh,
  OnBoard,
  TopPicks,
  Section,
}

export enum OnBoardingType {
  Conversations = "a_c",
  EvergreenArticles = "eg",
  FollowSubscription = "t_aut",
  MyNews = "t",
  RecommendedArticles = "a",
}
