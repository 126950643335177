import { AiEngineDataType } from "./enums";

export const AiEnginePaths = {
  [AiEngineDataType.HomeRecommendedForYou]: "/recommend/home",
  [AiEngineDataType.HomeYouMayHaveMissed]: "/recommend/may-have-missed",
  [AiEngineDataType.MoreOnThisTopic]: "/recommend/next",
  [AiEngineDataType.MyNewsFresh]: "/recommend/my-news/fresh",
  [AiEngineDataType.OnBoard]: "/recommend/on-board",
  [AiEngineDataType.Section]: "/recommend/section",
  [AiEngineDataType.TopPicks]: "/recommend/top-picks",
};

export const AiFallbackPaths = {
  [AiEngineDataType.HomeRecommendedForYou]: "/rec-backup/home.json",
  [AiEngineDataType.HomeYouMayHaveMissed]: "/rec-backup/may-have-missed.json",
  [AiEngineDataType.MoreOnThisTopic]: "/rec-backup/next.json",
  [AiEngineDataType.MyNewsFresh]: "/rec-backup/my-news/fresh.json",
  [AiEngineDataType.OnBoard]: "/rec-backup/on-board",
  [AiEngineDataType.Section]: "/rec-backup/section.json",
  [AiEngineDataType.TopPicks]: "/rec-backup/top-picks.json",
};
