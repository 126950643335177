// cspell: disable
import ImageChow from "./chow.png";
import ImageJacques from "./jacques.png";
import ImageTammy from "./tammy.png";

export const quotes = [
  {
    companyName: "South China Morning Post",
    image: ImageTammy.src,
    name: "Tammy Tam / Editor-in-Chief",
    quote: "“Unpack China’s complexity at a glance and shed light on opportunities”",
  },
  {
    companyName: "South China Morning Post",
    image: ImageChow.src,
    name: "Chow Chung-yan / Executive Editor",
    quote: "“We can show the real picture of what’s going on in China - and what it means for you”",
  },
  {
    companyName: "South China Morning Post",
    image: ImageJacques.src,
    name: "Jacques van Wersch / Director, SCMP Plus",
    quote: "“Clear, compact and contextual insight into China, for a competitive edge”",
  },
];
