import { type FunctionComponent } from "react";

import {
  DesktopContainer,
  MobileContainer,
  TabletContainer,
} from "scmp-app/components/home/placeholder";

import DesktopMyNewsPlaceholder from "./desktop-my-news-placeholder.svg";
import MobileMyNewsPlaceholder from "./mobile-my-news-placeholder.svg";
import TabletMyNewsPlaceholder from "./tablet-my-news-placeholder.svg";

type Props = {
  className?: string;
};

export const HomepageMyNewsPlaceholder: FunctionComponent<Props> = () => (
  <>
    <MobileContainer>
      <MobileMyNewsPlaceholder />
    </MobileContainer>
    <TabletContainer>
      <TabletMyNewsPlaceholder />
    </TabletContainer>
    <DesktopContainer>
      <DesktopMyNewsPlaceholder />
    </DesktopContainer>
  </>
);

HomepageMyNewsPlaceholder.displayName = "HomepageMyNewsPlaceholder";
