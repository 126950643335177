import styled from "@emotion/styled";
import { fontMerriweather, fontRobotoCondensed, transientOptions } from "@product/scmp-sdk";

import { StyledImage } from "scmp-app/components/common/base-image/styles";
import { ContentCoverImage } from "scmp-app/components/content/content-cover-image";
import { StyledFigcaption } from "scmp-app/components/content/content-cover-image/styles";
import { SponsorHeadline } from "scmp-app/components/content/content-headline/styles";
import { StyledTopicLink } from "scmp-app/components/content/content-topic-link/styles";
import { EntityLink } from "scmp-app/components/entity-link";
import { HomeFollowButton } from "scmp-app/components/home/follow-button";

export const Container = styled.div``;

export const StyledEntityLink = styled(EntityLink)`
  display: block;

  color: #000000;
`;

export const TopicContainer = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;

  margin-block-end: 8px;
`;

export const Topic = styled.div`
  ${StyledTopicLink} {
    color: #001246;
    font-weight: 700;
    font-size: 15px;
    font-family: ${fontRobotoCondensed};
    font-style: normal;
    line-height: 18px;
  }
`;

export const LeadingImage = styled.div`
  margin-block-end: 24px;

  ${StyledImage} {
    aspect-ratio: 2;
  }
`;

export const StyledContentCoverImage = styled(ContentCoverImage)``;

export const StyledHomeFollowButton = styled(HomeFollowButton)``;

export const Headline = styled.div`
  margin-block-end: 12px;

  font-weight: 700;
  font-size: 18px;
  font-family: ${fontMerriweather};
  line-height: 25.2px;

  ${SponsorHeadline} {
    font-weight: 700;
    font-style: normal;
    line-height: 22.4px;
  }
`;

export const Summary = styled.div`
  margin-block-end: 12px;

  color: #6f6f6f;
  font-size: 14px;
  line-height: 18.2px;

  &:empty {
    margin: 0;
  }
`;

export const ActionBar = styled.div`
  time {
    color: #999999;
    font-size: 12px;
  }
`;

type StyledCoverEntityLinkProps = {
  $isSponsorArticle?: boolean;
};
export const StyledCoverEntityLink = styled(EntityLink, {
  ...transientOptions,
})<StyledCoverEntityLinkProps>`
  display: ${props => (props.$isSponsorArticle ? "block" : "none")};

  margin-block-start: 12px;

  ${props => props.theme.breakpoints.up("tablet")} {
    display: block;
  }
`;

export const CoverImage = styled.div`
  inline-size: 120px;
  block-size: 80px;

  ${StyledFigcaption} {
    ${props => props.theme.breakpoints.up("tablet")} {
      line-height: 14px;
    }
  }
`;

export const Primary = styled.div`
  grid-area: primary;
  ${props => props.theme.breakpoints.up("tablet")} {
    margin-block-end: 0;
    padding-block-end: 0;

    border: none;
  }
`;
