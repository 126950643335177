import { theme, useResponsive } from "@product/scmp-sdk";
import { useResizeObserver, useSessionStorageValue } from "@react-hookz/web";
import { minutesToMilliseconds } from "date-fns";
import { useSetAtom } from "jotai";
import { useEffect, useRef, useState } from "react";
import { graphql, readInlineData } from "react-relay";
import { useIdle } from "react-use";
import CreateSeedRandom from "seed-random";

import { Edition } from "scmp-app/lib/edition";
import type { hooksPickMorningStudioArticle$key } from "scmp-app/queries/__generated__/hooksPickMorningStudioArticle.graphql";

import { whyRegisterHomepagePositionTriggerReferenceAtom } from "./atoms";

export const useLayoutWorkaroundForMyNews = (edition: Edition) => {
  const [myNewsElement, setMyNewsElement] = useState<HTMLDivElement | null>();
  const [secondSectionBlockElement, setSecondSectionBlockElement] =
    useState<HTMLDivElement | null>();
  const isDesktop = useResponsive(theme.breakpoints.up("desktop"), false);
  const [isSecondSectionHigherThanMyNews, setIsSecondSectionHigherThanMyNews] = useState(false);

  useResizeObserver(
    secondSectionBlockElement ?? null,
    _ => {
      const secondSectionElementHeight =
        secondSectionBlockElement?.getBoundingClientRect().height ?? 0;
      const myNewsElementHeight = myNewsElement?.getBoundingClientRect().height ?? 0;
      setIsSecondSectionHigherThanMyNews(secondSectionElementHeight > myNewsElementHeight);
    },
    edition === Edition.HongKong && isDesktop,
  );

  return {
    isSecondSectionHigherThanMyNews,
    setMyNewsElement,
    setSecondSectionBlockElement,
  };
};

export const useAutoRefreshWhenIdlingForTenMinutes = () => {
  const isIdle = useIdle(minutesToMilliseconds(10));

  const { setIsAutoRefreshed } = useIsAutoRefreshed();

  useEffect(() => {
    if (!isIdle) {
      return;
    }

    setIsAutoRefreshed("true");
    window.location.reload();
  }, [isIdle, setIsAutoRefreshed]);
};

export const useIsAutoRefreshed = () => {
  const {
    remove: removeIsAutoRefreshed,
    set: setIsAutoRefreshed,
    value: isAutoRefreshed,
  } = useSessionStorageValue<string>("is_auto_refreshed");

  return {
    isAutoRefreshed,
    removeIsAutoRefreshed,
    setIsAutoRefreshed,
  };
};

export const useWhyRegisterHomepagePositionTriggerReference = () => {
  const whyRegisterHomepagePositionTriggerReference = useRef(null);
  const setWhyRegisterHomepagePositionTriggerReference = useSetAtom(
    whyRegisterHomepagePositionTriggerReferenceAtom,
  );

  useEffect(() => {
    if (!whyRegisterHomepagePositionTriggerReference.current) {
      return;
    }
    setWhyRegisterHomepagePositionTriggerReference(whyRegisterHomepagePositionTriggerReference);
  }, [setWhyRegisterHomepagePositionTriggerReference]);

  return whyRegisterHomepagePositionTriggerReference;
};

export const usePickMorningStudioArticles = (
  seed: string,
  reference?: hooksPickMorningStudioArticle$key | null,
) => {
  const random = CreateSeedRandom(seed);
  const data = readInlineData(
    graphql`
      fragment hooksPickMorningStudioArticle on Queue @inline {
        items(first: 9) {
          edges {
            # eslint-disable-next-line relay/unused-fields
            node {
              ...aiArticleListSponsoredArticle
            }
            ...topStoriesQueueItemsEdge
          }
        }
      }
    `,
    reference ?? null,
  );

  const slot1Articles = (data?.items?.edges ?? []).slice(0, 2);
  const slot2Articles = (data?.items?.edges ?? []).slice(2);
  const slot1Picked = Math.floor(random() * slot1Articles.length);
  const slot2Picked = Math.floor(random() * slot2Articles.length);

  return [slot1Articles[slot1Picked], slot2Articles[slot2Picked]];
};
