import type { FunctionComponent } from "react";
import { useMemo } from "react";
import { graphql, useFragment } from "react-relay";

import type { plusWidgetNewsAgendaQuery$key } from "scmp-app/queries/__generated__/plusWidgetNewsAgendaQuery.graphql";

import IconArrow from "./icon-arrow.svg";
import { Container, MoreOnLink, PlusNewsAgendaItems, StyledAgendaItem } from "./styles";

type Props = {
  className?: string;
  pageType: "homepage" | "section" | "topic";
  reference: plusWidgetNewsAgendaQuery$key;
};

export const PlusWidgetNewsAgenda: FunctionComponent<Props> = ({
  className,
  pageType,
  reference,
}) => {
  const data = useFragment(
    graphql`
      fragment plusWidgetNewsAgendaQuery on Query
      @argumentDefinitions(
        applicationId: { type: "String!" }
        plusNewsAgendaStartDate: { type: "Timestamp!" }
        plusNewsAgendaEndDate: { type: "Timestamp!" }
        plusNewsAgendaTypes: { type: "[String]!" }
      ) {
        topics(
          first: 5
          applicationId: $applicationId
          filter: {
            types: $plusNewsAgendaTypes
            newsAgendaDateRange: {
              startDate: $plusNewsAgendaStartDate
              endDate: $plusNewsAgendaEndDate
            }
            newsAgendaOptions: ["key_event"]
          }
          orderBy: { field: NEWS_AGENDA_START_DATE, direction: ASC }
        ) {
          edges {
            node {
              ...itemAgendaItemTopic
              ...hooksNewsAgendaTopic
              newsAgenda {
                newsAgendaOptions
              }
            }
          }
        }
      }
    `,
    reference,
  );

  const updatedItems = useMemo(
    () =>
      data?.topics?.edges.filter(
        ({ node }) => !node.newsAgenda?.newsAgendaOptions?.includes("disable_detail_view"),
      ),
    [data?.topics?.edges],
  );

  return (
    <Container className={className}>
      <PlusNewsAgendaItems>
        {updatedItems.slice(0, 2).map(({ node }, index) => (
          <StyledAgendaItem
            gaEventType="discovery_module"
            key={index}
            needToShowUpdatedLabel={false}
            reference={node}
            showAdditionalContent={false}
          />
        ))}
      </PlusNewsAgendaItems>
      <MoreOnLink pathname="/plus/agenda" query={{ module: "content_discovery", pgtype: pageType }}>
        <span>More on News Agenda</span> <IconArrow />
      </MoreOnLink>
    </Container>
  );
};

PlusWidgetNewsAgenda.displayName = "PlusWidgetNewsAgenda";
