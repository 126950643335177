import { notEmpty } from "@product/scmp-sdk";
import { useMemo } from "react";
import { graphql, useFragment } from "react-relay";

import type { contentUtilsGetEntityIdsByQueueItemQueueItemsEdge$key } from "scmp-app/queries/__generated__/contentUtilsGetEntityIdsByQueueItemQueueItemsEdge.graphql";

export const useContentUtils = () => {
  /**
   * Extract text from schema node to string
   */
  const extractTextFromSchemaNodes = (item: SchemaNode | SchemaNode[]) => {
    const array = Array.isArray(item) ? item : [item];
    return array.reduce((output, current) => {
      if (current?.type === "text" && current?.data && current?.data !== "\n") {
        output += current.data.trim();
      }
      if (current.children) {
        output += current.children.map(child => extractTextFromSchemaNodes(child)).join(" ");
      }

      return output;
    }, "");
  };

  return {
    extractTextFromSchemaNodes,
  };
};

export const useGetEntityIdsByQueueItems = (
  reference: contentUtilsGetEntityIdsByQueueItemQueueItemsEdge$key,
  existingEntityIds: string[] = [],
) => {
  const edges = useFragment(
    graphql`
      fragment contentUtilsGetEntityIdsByQueueItemQueueItemsEdge on QueueItemsEdge
      @relay(plural: true) {
        node {
          ... on Content {
            entityId
          }
        }
      }
    `,
    reference,
  );

  return useMemo(
    () => [
      ...new Set([...edges.map(edge => edge.node.entityId).filter(notEmpty), ...existingEntityIds]),
    ],
    [edges, existingEntityIds],
  );
};
